import { UPDATE_COMMENT_LOCAL, UPDATE_COMMENT_REMOTE } from "../../constants/action-types";

const initialState = {
    commentLocal:{},
    commentRemote:{}
};

export default function CommentReducer(state = initialState, action){

  switch(action.type){
    case UPDATE_COMMENT_LOCAL : {
      return Object.assign({},state, {
        commentLocal:action.payload,
      })
    }

    case UPDATE_COMMENT_REMOTE : {
      return Object.assign({},state, {
        commentLocal:action.payload,
        commentRemote:action.payload,
      })
    }

    default:{
      return state;
    }
  }

}