import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';

import { closeAlert } from '../../actions/alerts';
import { CSSTransition, TransitionGroup} from 'react-transition-group';

class AlertMsg extends React.Component {

  successMsgs = [ "Pipeline dispatched", 'sequence copied', "table refreshed", "deletion successful", 'preset saved', "updating the preset was successful", 'deleting the preset was successful', 'the preset has been reset', "Save successful"];
  componentDidUpdate(prevProps){
    if(prevProps.alertMsgs.length < this.props.alertMsgs.length){
      window.setTimeout(()=>{
        this.props.closeAlert(0);
      },7000);
    }
  }

  render() {
    return(
      <div>     
        <TransitionGroup> 
        {  
          this.props.alertMsgs.map((alert, index) =>
            <CSSTransition
              key={index}
              classNames='fade'
              timeout={{exit:300}}
            >
              <Alert className={'fade fade-exit'} variant={this.successMsgs.includes(alert)?"success":"danger"} key={index} onExit= {()=> console.log("this won't work")}>
                {alert}
              </Alert>
            </CSSTransition>
          )
        }
        </TransitionGroup>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    alertMsgs: state.alerts.alertMsgs
  }
}

export default connect(mapStateToProps,{closeAlert})(AlertMsg);