import axios from 'axios';
import React from 'react';
import ReactTable from "react-table-v6";
import { ReactTableDefaults } from 'react-table-v6';
import "react-table-v6/react-table.css"
// import { Table, ProgressBar, Button } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addAlert } from '../../actions/alerts';
import { runsAction } from '../../actions/action'
import ReactTooltip from 'react-tooltip'


class PipelineSteps extends React.Component {
    constructor(props) {
        super()
        this.state = {
            currentProgress: 'Loading data',
            data: [],
            log: ''
        }
    }

    requestData() {
        axios.post('pipelineGo/progress', {
            project: this.props.project,
            user: this.props.user
        }).then(res => {
            if (res.data.query === 'failed') {
                this.props.addAlert({ message: res.data.msg });
                if (this.state.data.length !== 0) {
                    this.setState({ data: [] });
                }
            } else {
                if (JSON.stringify(this.state.data) !== JSON.stringify(res.data.data)) {
                    this.setState({ data: res.data.data });
                }
                // if(JSON.stringify(this.state.data).includes("running")){
                //     setTimeout(()=>{this.requestData()},2000);
                // }
            }
        }).catch(err => {
            this.props.addAlert({ message: `unable to access pipeline information: ${err}` });
            if (this.state.data.length !== 0) {
                this.setState({ data: [] });
            }
        });
    }

    pipelineProgress = () => {
        axios.post('file/log', { project: this.props.project, user: this.props.user })
            .then(res => {
                var logs = res.data
                if (JSON.stringify(this.state.log) !== JSON.stringify(logs)) {
                    this.setState({ log: logs })
                }
            })
    }

    /*Date(this.data[0].date)
    Object.keys(res.data[1])*/
    componentDidUpdate() {
        this.requestData();
        this.pipelineProgress()
    }

    componentDidMount() {
        this.requestData();
        this.pipelineProgress()
    }

    refresh = () => {
        this.forceUpdate()
        this.props.addAlert({ message: `table refreshed` })
        axios.post('file/listResults', { user: this.props.user })
            .then(res => {
                this.props.runsAction(res.data)
            })
    }

    render() {
        /*let table = <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Run-name</th>
                    <th>Date</th>
                    <th >Status</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(this.state.data).map(d => (
                    <tr key={d}>
                        <td>{this.state.data[d].name}</td>
                        <td>{this.state.data[d].date}</td>
                            <td>{this.state.data[d].status === "running" ?
                            <ProgressBar style={{ 'minWidth': '200px' }} animated now={this.state.data[d].prog} /> :
                            this.state.data[d].status}
                        </td>
                    </tr>
                ))}

            </tbody>
        </Table>
        */

        const cols = [{ Header: 'Run-name', accessor: 'name' },
        { Header: 'Start', accessor: 'date', sortMethod: (a, b) => {
            const x = new Date(a).getTime();
            const y = new Date(b).getTime();
            return x > y ? 1 : -1;
          } },
        { Header: 'Status', accessor: 'status' },
        { Header: 'Log', accessor: 'log' },
        { Header: 'Step', accessor: 'step' },
        { Header: 'End', accessor: 'end' },
            //{ Header: 'Progress',accessor: 'prog'}
        ]


        var disp_data = JSON.parse(JSON.stringify(this.state.data))
        //if (JSON.stringify(this.state.log) !== JSON.stringify(logs)) {
        disp_data.forEach(run => {
            if (run.date) {
                run.date = new Date(run.date).toString()
                    .replace(" GMT+0200 (Central European Summer Time)", "")
                    .replace(" GMT+0100 (Central European Standard Time)", "");
            }
            if (run.end) {
                run.end = new Date(run.end).toString()
                    .replace(" GMT+0200 (Central European Summer Time)", "")
                    .replace(" GMT+0100 (Central European Standard Time)", "");
            }
            if (this.state.log) {
                if (this.state.log.map(x => new Date(Number(Object.keys(x)[0])).toString()).includes(new Date(run.date).toString())) {
                    var ind = this.state.log.map(x => new Date(Number(Object.keys(x)[0])).toString()).indexOf(new Date(run.date).toString())
                    var time_num = this.state.log.map(x => Number(Object.keys(x)[0]))[ind]
                    run.date = new Date(run.date).toString()
                        .replace(" GMT+0200 (Central European Summer Time)", "")
                        .replace(" GMT+0100 (Central European Standard Time)", "");
                    run.step = Object.values(this.state.log.filter(x => Number(Object.keys(x)) === time_num)[0])[0]
                }
            }
        })

        let table = <ReactTable
            defaultSorted={[
                {
                    id: "date",
                    desc: true
                }
            ]}
            columns={cols}
            column={{ ...ReactTableDefaults.column, minWidth: 150 }}
            data={disp_data}
        >
        </ReactTable>

        //}
        return (
            <div style={{ display: 'table' }}>
                <h4>{this.currentProgress}</h4>
                <Button variant="secondary" size="sm" style={{ "marginBottom": "8px" }} onClick={this.refresh} data-tip={"Refresh progress table and result list if they're not visible."} >Refresh</Button>
                <br />
                <ReactTooltip />
                {table}
            </div>
        )
    }
}

//Passing global state as props (redux)
const mapStateToProps = (state) => {
    return {
        user: state.user,
        runSamples: state.runSamples,
        projects: state.projects,
        project: state.project
    }
}
const mapDispatchToProps = { addAlert, runsAction };
export default connect(mapStateToProps, mapDispatchToProps)(PipelineSteps);
