import { API_CALL_FAILED, CLOSE_ALERT, ADD_ALERT} from "../../constants/action-types";

const initialState = {
  alertMsgs: []
};

export default function AlertReducer(state = initialState, action) {
  switch (action.type) {
    
    case API_CALL_FAILED  : {
      // console.log( "api called failed");
      return Object.assign({}, state, {
        alertMsgs: [...state.alertMsgs.slice(), "" +action.payload.message]
      });
    }

    case ADD_ALERT: {
      // console.log( "api called failed");
      return Object.assign({}, state, {
        alertMsgs: [...state.alertMsgs.slice(), "" +action.payload.message]
      });
    }

    case CLOSE_ALERT: {
      return Object.assign({}, state, {
        alertMsgs: [...state.alertMsgs.slice(0, action.payload), ...state.alertMsgs.slice(action.payload + 1)]
      });
    }

    default: {
      return state;
    }
  }
}
