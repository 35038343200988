import React from 'react'

class CreateNewProject extends React.Component {

    constructor(props) {
        super(props)
        this.state = {} //Necessary to operate on states, can set defaults here
    }

    handleChange = (event) => {  //textarea event handler
        this.setState({ x: event.target.value }); //set textarea state by new textarea input
        this.props.newFun(event.target.value) //NOTE: when putting this.state.x here, the button displaying is delayed by 1 action due to asynchronus execution I guess
    }

    render() {

        if (['<Create a run (upload)>', '<Create a run (server)>'].includes(this.props.project)) {
            return (
                <div style={{ display: 'flex', margin: '12px' }}>
                    {/* <h6>Name of the run</h6> */}
                    <div className="wrap-input100">
                        <input className="input100" type="text" id='proj' placeholder="Name of the run" value={this.state.x} onChange={this.handleChange} />
                        <span className="focus-input100"></span>
                    </div>
                    {/* <input type="text" id='run'></input> */}
                </div>
            )
        }
        return (null)
    }
}

export default CreateNewProject