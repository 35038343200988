import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {Button} from 'react-bootstrap';
import {
    addAlert
  } from '../../actions/alerts';
// import Button from 'react-bootstrap/Button';

class PipelineButton extends React.Component {
    constructor(props) {
        super()
        this.state = {
            buttonStatus: 'go',
            response: '',
            runName:""
        }
    }


    handleInput = (x)=> {
        this.setState({runName:x.target.value});
    }

    handleClick = () => {

        this.setState({ buttonStatus: 'running!' });

        console.log("this.props.SNVCNVPipe");
        console.log(this.props.SNVCNVPipe);
        console.log("this.props.rearTransPipe");
        console.log(this.props.rearTransPipe);

        axios.post('pipelineGo', {
            user: this.props.user,
            project: this.props.project,
            run: this.props.project,
            SNVCNVPipe: this.props.SNVCNVPipe,
            rearTransPipe: this.props.rearTransPipe,
            samples: this.props.samples// selecting samples from table doesn't work yet
                // .filter((item, index) => this.props.runSamples.includes(index))
                // .map(x => x.sample)
        }).then(res => { 
            if(res.data.msg === "OK"){
                this.props.addAlert({message: "Pipeline dispatched."});
            }
            this.setState({buttonStatus:'go'});
        }).catch(err =>{
            this.props.addAlert({message:`Error occured while trying to run analysis: ${err}`})
        });
    }

    render() {

        var status = (this.state.buttonStatus === 'go') ? "GO!" : "running!";
        var color = (this.state.buttonStatus === 'go') ? "#1fbf52" : "#ffce00";
        var display = this.props.project !== null & this.props.project !== '<Create a run (upload)>'
        //below is temporary!
        var disabled = this.props.project ? [null, undefined, '', Object.keys(this.props.runs), '<Create a run (upload)>'].includes(this.props.project) : true

        return (
            <div>
                <Button className="runPipelineBtn" onClick={this.handleClick} style={{ backgroundColor: color, display: display }} disabled={disabled} >{status}</Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        runSamples: state.runSamples,
        runs: state.runs,
        run: state.run,
        project: state.project,
        projects: state.projects
    }
}
const mapDispatchToProps = {
    addAlert
}; // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 
export default connect(mapStateToProps, mapDispatchToProps)(PipelineButton);