import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import axios from 'axios'

import Selection from '../Shared/Selection'
import CreateNewProject from './CreateNewProject'
import SamplesTab from './SamplesTab'
import PipelineSteps from './PipelineSteps'
import PipelineButton from './PipelineButton'
import { connect } from 'react-redux'
import { userAction, projectAction, runSamplesAction, serverProjectsAction, projectsAction } from '../../actions/action'
import { Row, Col, Button, ProgressBar } from 'react-bootstrap'
import { addAlert } from '../../actions/alerts'
import ReactTooltip from 'react-tooltip'

class Analysis extends React.Component {

    constructor(props) {
        super(props)
        this.projectHandler = this.projectHandler.bind(this)
        this.serverProjectHandler = this.serverProjectHandler.bind(this)
        this.state = {
            currentProject: null,
            newProjectName: null,
            files: null,
            fileUpload: 0,
            SNVCNVPipe: true,
            rearTransPipe: true,
            serverProject: null
        }
    }

    projectHandler(e) {
        // this.setState({ currentProject: e })
        this.props.projectAction(e)
        this.props.runSamplesAction([])
    }
    serverProjectHandler(e) {
        this.setState({ serverProject: e })
    }

    newHandler = e => {
        this.setState({ newProjectName: e })
    }
    newHandlerServer = e => {
        this.setState({ newProjectName: e })
    }

    createHandler = () => {
        axios.post('file/project', { user: this.props.user, project: this.state.newProjectName })
            .then(res => {
                //switch to current
                this.setState({ currentProject: res.data })
            }).then(() => {
                //update project list
                axios.post('file/list', { user: this.props.user })
                    .then(res => {
                        // this.props.projectsAction(res.data.map(x => [{ value: Object.keys(x)[0], label: Object.keys(x)[0], samples: Object.values(x)[0] }][0]))
                        this.props.projectsAction(res.data.map(x => [{
                            value: Object.keys(x)[0],
                            label: Object.keys(x)[0],
                            samples: Object.values(x)[0].map(y => Object.keys(y)[0]),
                            sizes: Object.values(x)[0].map((y, i) => ({ [Object.values(x)[0].map(y => Object.keys(y)[0])[i]]: Object.values(y)[0] }))
                        }][0]));
                    })
                this.props.projectAction(this.state.newProjectName)
            })
    }

    createHandlerServer = () => {
        axios.post('file/serverProject', { user: this.props.user, project: this.state.newProjectName, serverProject: this.state.serverProject })
            .then(res => {
                //switch to current
                this.setState({ currentProject: res.data })
            }).then(() => {
                //update project list
                axios.post('file/list', { user: this.props.user })
                    .then(res => {
                        if (res !== null) {
                            // this.props.projectsAction(res.data.map(x => [{ value: Object.keys(x)[0], label: Object.keys(x)[0], samples: Object.values(x)[0] }][0]))
                            this.props.projectsAction(res.data.map(x => [{
                                value: Object.keys(x)[0],
                                label: Object.keys(x)[0],
                                samples: Object.values(x)[0].map(y => Object.keys(y)[0]),
                                sizes: Object.values(x)[0].map((y, i) => ({ [Object.values(x)[0].map(y => Object.keys(y)[0])[i]]: Object.values(y)[0] }))
                            }][0]));
                        }
                    })
                this.props.projectAction(this.state.newProjectName)
            })
    }


    fileHandler = async e => {
        //this.setState({ files: e.target.files[0] })
        if (e.target.files.length > 0) {
            var fd = new FormData()
            Object.values(e.target.files).forEach(file => {
                fd.append("file", file)
            })
            fd.append("project", this.props.project)
            fd.append("user", this.props.user)

            this.setState({ files: e.target.files })

            var opts = {
                onUploadProgress: progressEvent => {
                    this.setState({ fileUpload: 100 * progressEvent.loaded / progressEvent.total })
                    if (this.state.fileUpload === 100) {
                        this.setState({ fileUpload: 0 })
                    }
                    console.log('Upload Progress: ' + 100 * progressEvent.loaded / progressEvent.total)
                }
            }

            axios.post('file/check', { names: Object.values(e.target.files).map(x => x.name), project: this.props.project, user: this.props.user })
                .then(res => {
                    if (res.data.length > 0) {
                        var fileNames = res.data.map(x => x.split('/')[x.split('/').length - 1]).join(', ')
                        if (res.data.length > 1) {
                            this.props.addAlert({ message: 'Files: ' + fileNames + ' already exist, overwriting!' })
                        } else {
                            this.props.addAlert({ message: 'File: ' + fileNames + ' already exists, overwriting!' })
                        }
                    }
                })

            axios.post('file/save', fd, opts).then(res => {
                axios.post('file/list', { user: this.props.user })
                    .then(res => {
                        // this.props.projectsAction(res.data.map(x => [{ value: Object.keys(x)[0], label: Object.keys(x)[0], samples: Object.values(x)[0] }][0]))
                        this.props.projectsAction(res.data.map(x => [{
                            value: Object.keys(x)[0],
                            label: Object.keys(x)[0],
                            samples: Object.values(x)[0].map(y => Object.keys(y)[0]),
                            sizes: Object.values(x)[0].map((y, i) => ({ [Object.values(x)[0].map(y => Object.keys(y)[0])[i]]: Object.values(y)[0] }))
                        }][0]));
                    })

            })

        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.currentProject != null) {
            this.setState({ currentProject: null })
        }
    }

    checkChange = () => {
        Object.values(this.refs).map(x => x.checked === true ? this.setState({ [x.name]: true }) : this.setState({ [x.name]: false }))
    }

    render() {
        var selectedProject = this.state.currentProject ?
            this.state.currentProject !== this.props.project ?
                this.state.currentProject : this.props.project : this.props.project

        var runName_height = '<Create a run (upload)>' === selectedProject ? '100px' : "0px";
        var serverRun_style = '<Create a run (server)>' === selectedProject ? { height: '140px', overflow: 'visible' } : { height: '0px', overflow: 'hidden' };


        // Project list for the given user

        // natural sorting helper
        const comparator = (a, b) => {
            return a.value.toString().localeCompare(b.value.toString(), 'en', { numeric: true })
        };
        var projects = this.props.projects ? this.props.projects.sort(comparator) : null

        if (this.props.user == 'cmbgtfnbrno') {
            projects = projects ? [
                {
                    label: '<Create a run (upload)>',
                    value: '<Create a run (upload)>'
                },
                {
                    label: '<Create a run (server)>',
                    value: '<Create a run (server)>'
                }
            ].concat(projects) : []
        } else {
            projects = projects ? [
                {
                    label: '<Create a run (upload)>',
                    value: '<Create a run (upload)>'
                }
            ].concat(projects) : []
        }
        

        // Allow file upload for users
        var toggle_upload = this.props.projects ? this.props.projects.map(x => x.value).includes(selectedProject) ? false : true : true

        var samples = this.props.projects ?
            selectedProject ?
                this.props.projects.map(x => x.value === selectedProject ? x.samples : null)
                    .filter(function (el) { return el != null })[0] :
                null :
            null

        var newProj_tooltip = "";
        var newProj_toggle = false;
        if (this.props.projects) {
            if (selectedProject === '<Create a run (server)>' && (this.state.serverProject === "" || this.state.serverProject == null)) {
                newProj_toggle = true;
                newProj_tooltip = 'Please, select server-stored data first.';
            } else if ([...this.props.projects.map(x => x.value), '<Create a run (upload)>', '<Create a run (server)>'].includes(this.state.newProjectName)) {
                newProj_toggle = true;
                newProj_tooltip = 'The run name already exists.';
            } else if (['', null].includes(this.state.newProjectName)) {
                newProj_toggle = true;
                newProj_tooltip = 'The run name cannot be empty.';
            } else if (this.state.newProjectName !== null && (this.state.newProjectName.startsWith(".") || this.state.newProjectName.includes(" "))) {
                newProj_toggle = true;
                newProj_tooltip = 'The run name cannot start with a dot or contain spaces.';
            }
        }

        return (
            <div >
                <Row>
                    <Col xs={3}>
                        <h6>Create or select the run</h6>
                        <Selection
                            projectFun={this.projectHandler}
                            choices={projects}
                            value={{ value: selectedProject, label: selectedProject }} />
                        <div id='runName_grow' style={{ height: runName_height }} xs={3}>
                            <div>
                                <span className="focus-input100"></span>
                                <CreateNewProject
                                    project={this.props.project}
                                    newFun={this.newHandler} />
                            </div>
                            <div>
                                <Button
                                    onClick={this.createHandler}
                                    variant="primary"
                                    disabled={newProj_toggle} >
                                    <div data-tip={newProj_tooltip} data-tip-disable={false}>
                                        Create the run
                                    </div>
                                </Button>
                            </div>
                        </div>
                        <div id='serverRun_grow' style={serverRun_style} xs={3}>

                            <Selection
                                projectFun={this.serverProjectHandler}
                                choices={this.props.serverProjects}
                                value={{ value: this.state.serverProject, label: this.state.serverProject }} />
                            <div>
                                <span className="focus-input100"></span>
                                <CreateNewProject
                                    project={this.props.project}
                                    newFun={this.newHandlerServer} />
                            </div>
                            <div>
                                <Button
                                    onClick={this.createHandlerServer}
                                    variant="primary"
                                    disabled={newProj_toggle} >
                                    <div data-tip={newProj_tooltip} data-tip-disable={false}>
                                        Create the run
                                    </div>
                                </Button>
                            </div>
                        </div>
                        <ReactTooltip />
                    </Col>
                    <Col xs={2} style={{ display: 'grid' }}>
                        <h6>Upload the samples</h6>
                        <input
                            type="file"
                            name="files"
                            id="files"
                            multiple
                            onChange={this.fileHandler}
                            disabled={toggle_upload}>
                        </input>
                        <ProgressBar style={{ 'minWidth': '200px', visibility: this.state.fileUpload !== 0 ? 'visible' : 'hidden' }} animated now={this.state.fileUpload} />
                    </Col>
                    <Col xs={4}>
                        <h6>Select analysis type</h6>
                        <Row>
                            <Col xs={4}>
                                <input type="checkbox" name="SNVCNVPipe" ref="SNVCNVPipe" onChange={this.checkChange} defaultChecked={this.state.SNVCNVPipe} />
                                <label htmlFor="SNVCNVPipe" style={{ 'paddingLeft': '5px' }}>SNV + CNV</label>
                            </Col>
                            <Col xs={8}>
                                <input type="checkbox" name="rearTransPipe" ref="rearTransPipe" onChange={this.checkChange} defaultChecked={this.state.rearTransPipe} />
                                <label htmlFor="rearTransPipe" style={{ 'paddingLeft': '5px' }}> rearrangements + translocations</label>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2}>
                        <h6>Run the analysis</h6>
                        <PipelineButton
                            state='go'
                            newProject={this.state.newProjectName}
                            samples={samples}
                            SNVCNVPipe={this.state.SNVCNVPipe}
                            rearTransPipe={this.state.rearTransPipe}
                        />

                    </Col>
                </Row>


                <Tabs>
                    <TabList>
                        <Tab>Samples</Tab>
                        <Tab>Pipeline progress</Tab>
                    </TabList>
                    <TabPanel>
                        <SamplesTab samples={samples} />
                    </TabPanel>
                    <TabPanel>
                        <PipelineSteps />
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

//Passing global state as props (redux)
const mapStateToProps = (state) => {
    return {
        user: state.user ? state.user : null,
        project: state.project,
        projects: state.projects,
        serverProjects: state.serverProjects
    }
}
const mapDispatchToProps = { userAction, projectAction, runSamplesAction, serverProjectsAction, projectsAction, addAlert } // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 
export default connect(mapStateToProps, mapDispatchToProps)(Analysis)