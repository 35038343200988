import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Selection from '../Shared/Selection'
// import SearchTable from './SearchTable'
import Summary from './Summary'
import SNV from './SNV'
import CNV from './CNV'
import Rearrangements from './Rearrangements'
import Translocations from './Translocations'
import IGV from './IGV'
import { connect } from 'react-redux'
import { presetAction, projectAction, runAction, sampleAction, userAction, filterAction, presetListAction, tabIndexAction } from '../../actions/action';
import { addAlert } from '../../actions/alerts';
import { updateCommentRemote } from '../../actions/comment';
import axios from 'axios';
import { Modal, Row, Col, Button } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

class Results extends React.Component {

    constructor() {
        super()
        this.runHandler = this.runHandler.bind(this);
        this.sampleHandler = this.sampleHandler.bind(this);
        this.presetHandler = this.presetHandler.bind(this);
        this.state = {
            cols: null,
            rearrangements: null,
            translocations: null,
            infoRearTrans: null,
            SNV1: null,
            SNV2: null,
            CNVtab: null,
            CNV: null,
            QC: null,
            presetName: null,
            sample: null,
            COV: null,
            waitingForComment: "",
            showDelete: false,
            growFilterActions: false
        }
    }

    runHandler(e) {
        this.props.runAction(e)
        this.setState({
            rearrangements: null,
            translocations: null,
            infoRearTrans: null,
            SNV1: null,
            SNV2: null,
            CNVtab: null,
            CNV: null,
            QC: null,
            COV: null
        })
        if (e != null) {
            /*this.setState({ rearrangements: this.props.userTables[0] })
            this.setState({ SNV1: this.props.userTables[1] })
            this.setState({ SNV2: this.props.userTables[2] })
            this.setState({ CNVtab: this.props.userTables[3] })
            this.setState({ CNV: this.props.userTables[4] })*/
            axios.post('backend', { user: this.props.user, run: e, runs: this.props.runs })
                .then(res => {
                    this.setState({
                        rearrangements: res.data[0],
                        translocations: res.data[1],
                        SNV1: res.data[2],
                        SNV2: res.data[3],
                        CNVtab: res.data[4],
                        CNV: res.data[5],
                        QC: res.data[6],
                        COV: res.data[7],
                        infoRearTrans: res.data[8]
                    })

                })
        }
        this.props.sampleAction(null)

    }

    sampleHandler(e) {

        this.props.sampleAction(e);
        this.setState({ sample: e });
        this.setState({ waitingForComment: "Loading comments from database..." });
        this.props.updateCommentRemote({});

        axios.post('comment/read', { user: this.props.user, run: this.props.run, sample: e })
            .then(res => {
                this.props.updateCommentRemote(res.data);
                this.setState({ waitingForComment: "" });
            }).catch(err => { this.props.addAlert(`failed to load comment ${err}`) });

    }

    presetHandler(e) {
        this.props.presetAction(e)
    }

    newPresHandler = (e) => {
        this.setState({ presetName: e.target.value })
    }

    callAPI() {
        if (this.props.run != null) {
            axios.post('backend', { user: this.props.user, run: this.props.run, runs: this.props.runs })
                .then(res => {
                    this.setState({
                        rearrangements: res.data[0],
                        translocations: res.data[1],
                        SNV1: res.data[2],
                        SNV2: res.data[3],
                        CNVtab: res.data[4],
                        CNV: res.data[5],
                        QC: res.data[6],
                        COV: res.data[7],
                        infoRearTrans: res.data[8]
                    })
                })
        }
    }

    presetSave = () => {
        var new_preset = { [this.state.presetName]: this.props.globalFilters[this.props.preset] }

        if (new_preset[this.state.presetName].length !== 0) {
            axios.post(`preset`, { new_preset: new_preset, user: this.props.user })
                .then(res => {
                    if (res !== 'saving the object failed') {
                        this.props.filterAction(new_preset)
                        this.props.presetListAction(Object.keys(this.props.globalFilters))//.map(x => [{ value: x, label: x }][0])) 
                        this.props.presetAction(this.state.presetName)
                        this.props.addAlert({ message: 'preset saved' })
                    }
                })
        } else {
            //inform about an empty preset
        }
    }

    presetUpdate = () => {
        axios.post(`preset/update`, { upd_preset: this.props.globalFilters[this.props.preset], preset_name: this.props.preset, user: this.props.user })
            .then(res => {
                this.props.addAlert({ message: res.data })
            })
    }


    presetDeleteAsk = () => {
        this.setState({ showDelete: true })
    }

    handleModalClose = () => {
        this.setState({ showDelete: false })
    }

    presetDelete = () => {
        axios.post(`preset/delete`, { del_preset: this.props.preset, user: this.props.user })
            .then(res => {
                this.props.addAlert({ message: res.data })
                if (res.data === 'deleting the preset was successful') {
                    var tmp = this.props.globalFilters
                    delete tmp[this.props.preset]
                    this.props.filterAction(tmp)
                    this.props.presetListAction(this.props.presetList.filter(e => e !== this.props.preset))
                    this.props.presetAction('No preset')
                }
            })
        this.setState({ showDelete: false })
    }


    presetReset = () => {
        axios.post(`userData`, { user: this.props.user })
            .then(res => {
                if (res.data.presets !== undefined) {
                    var currentFilters = res.data.presets[this.props.preset]
                    this.props.filterAction({ reset: { [this.props.preset]: currentFilters } })
                    this.props.addAlert({ message: 'the preset has been reset' })
                } else {
                    this.props.addAlert({ message: 'no presets exist, you probably need to log in first' })
                }
            })
    }

    componentDidMount() {
        this.callAPI()
    }

    growFilterActions = () => {
        this.setState({ growFilterActions: !this.state.growFilterActions })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.backend !== this.props.backend) {
            this.callAPI()
        }
    }

    callIMGT = () => {
        axios.post(`IMGT`, null)
    }


    render() {
        // natural sorting helper
        const comparator = (a, b) => {
            return a.toString().localeCompare(b.toString(), 'en', { numeric: true })
        };
        var runs = this.props.runs ? Object.keys(this.props.runs).sort(comparator).map(x => [{ value: x, label: x }][0]) : []
        var samples = this.props.runs != null & this.props.run != null ? Object.keys(this.props.runs[this.props.run]).map(x => [{ value: x, label: x }][0]) : []
        //var samples = this.props.run ? Object.keys(this.props.runs[this.props.run]).map(x => [{ value: x, label: x }][0]) : null
        var presets = this.props.user ? this.props.presetList ? this.props.presetList.map(x => [{ value: x, label: x }][0]) :
            [{ value: 'No preset', label: 'No preset' }] : [{ value: 'No preset', label: 'No preset' }]
        //var buttonDisp = this.props.preset == 'New preset' ? 'block' : 'none'


        var SNV1 = this.state.SNV1 != null & this.props.sample != null ? this.state.SNV1[this.props.sample] : null
        var SNV2 = this.state.SNV2 != null & this.props.sample != null ? this.state.SNV2[this.props.sample] : null
        var CNVplot = this.state.CNV != null & this.props.sample != null ? this.state.CNV[this.props.sample] : null
        var CNVtab = this.state.CNVtab != null & this.props.sample != null ? this.state.CNVtab[this.props.sample] : null
        var rearrangements = this.state.rearrangements != null & this.props.sample != null ? this.state.rearrangements[this.props.sample] : null
        var translocations = this.state.translocations != null & this.props.sample != null ? this.state.translocations[this.props.sample] : null
        var infoRearTrans = this.state.infoRearTrans != null & this.props.sample != null ? this.state.infoRearTrans[this.props.sample] : null
        var QC = this.state.QC != null ? this.state.QC : null
        this.analyses = this.state.SNV1 ? samples.map(x => x.value).map(x => [{
            Sample: x,
            SNV: this.state.SNV1 ? this.state.SNV1[x] === false ? '-' : '+' : '-',
            CNV: this.state.CNV ? this.state.CNV[x] ? this.state.CNV[x][2] === false ? '-' : '+' : '-' : '-',
            'translocations/rearrangements': this.state.rearrangements ? this.state.rearrangements[x] === false ? '-' : '+' : '-'
        }][0]) : null

        var visibility = this.state.presetName != null & !presets.map(x => x.value).concat(['', 'reset']).includes(this.state.presetName) ? true : false
        var save_preset_disabled = this.props.globalFilters[this.props.preset] ? this.props.globalFilters[this.props.preset].length !== 0 & visibility ? false : true : true
        var tooltip = !save_preset_disabled ? '' : 'New preset cannot be empty, have an empty or repeating name'

        var filterActions_growPars = this.state.growFilterActions ? { label: <div><FontAwesomeIcon icon={faAngleUp} /> Hide filter actions</div>, height: "35px" } : { label: <div><FontAwesomeIcon icon={faAngleDown} /> Show filter actions</div>, height: "0px" };



        var deleteModal = <div>
            <Modal show={this.state.showDelete} onHide={this.handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Do you really want to delete the preset?</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="danger" onClick={this.presetDelete}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={this.handleModalClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

        return (
            <div>
                {deleteModal}
                {/* <Button variant="secondary" onClick={this.callIMGT} > Call IMGT </Button>
                <a target="_blank" href="http://imgt.igh.cnrs.fr/IMGT_vquest/analysis?species=human&receptorOrLocusType=IG&inputType=inline&sequences=%3E1%0ActggatccgccagtccccagataaggggctggagtggattggcgaagtcaatgatagtggagtcaccaccgacaacccgtccctcgggagtcgggtccccatatcaggagacacgtccaagaagcagttctccctgaagatgatctctgtgaccgccgctgacacggctgtctataatTGTGCGTGGCGATTTTATTGTACTGCTGCTAACTGCAACAGTCCCCATTATTACTTCTACTACGGTTTGGACGTCTGGggccccgggaccacggtcaccgtctcctca&outputType=html&nbNtPerLine=10000&sv_V_GENEordertable=0&resultType=detailed&sv_V_GENEalignment=false&sv_V_REGIONalignment=false&sv_V_REGIONtranslation=true&sv_V_REGIONprotdisplay=false&sv_V_REGIONprotdisplay2=false&sv_V_REGIONprotdisplay3=false&sv_V_REGIONfrequentAA=false&sv_IMGTjctaResults=true&IMGTrefdirSet=1&IMGTrefdirAlleles=true&V_REGIONsearchIndel=true&nb5V_REGIONignoredNt=0&nb3V_REGIONaddedNt=0" > Visit IMGT </a> */}
                <Row >
                    <Col md>
                        <h6>Select the run</h6>
                        <Selection
                            id='project'
                            projectFun={this.runHandler}
                            choices={runs}
                            value={{ value: this.props.run, label: this.props.run }} />
                    </Col>
                    <Col md>
                        <h6>Select the sample</h6>
                        <Selection
                            projectFun={this.sampleHandler}
                            choices={samples}
                            value={{ value: this.props.sample, label: this.props.sample }} />
                    </Col>
                    <Col md>
                        <h6>Filter presets</h6>
                        <Selection
                            projectFun={this.presetHandler}
                            choices={presets}
                            preset={true}
                            value={{ value: this.props.preset, label: this.props.preset }} />
                    </Col>
                    <Col xs={2} style={{ "display": "flex", "alignItems": "flex-end" }}>
                        <Button variant="info" onClick={this.growFilterActions} size="sm" id="filterActions_button" className="fillAvailableBtns"> {filterActions_growPars.label} </Button>
                    </Col>
                </Row>
                <div id="filterActions_grow" style={{ height: filterActions_growPars.height }} >
                    <Row>
                        <Col xs={3}>
                            <input
                                id="newPresetName"
                                type="text"
                                onChange={this.newPresHandler}
                                placeholder={'New preset name'}
                                disabled={this.props.user !== null ? false : true}>
                            </input>
                        </Col>
                        <Col xs={3}>
                            <Button disabled={save_preset_disabled} variant="secondary" size='sm' className="fillAvailableBtns" onClick={this.presetSave} >
                                <div data-tip={tooltip} data-tip-disable={false}>
                                    Save the new preset
                                    </div>
                            </Button>
                        </Col>
                        <Col xs={2}>
                            <Button disabled={this.props.preset === 'No preset'} variant="secondary" size='sm' className="fillAvailableBtns" onClick={this.presetUpdate} >Update current preset</Button>
                        </Col>
                        <Col xs={2}>
                            <Button variant="secondary" size='sm' className="fillAvailableBtns" onClick={this.presetReset}>Reset the preset</Button>
                        </Col>
                        <Col xs={2}>
                            <Button disabled={this.props.preset === 'No preset'} variant="danger" size='sm' className="fillAvailableBtns" onClick={this.presetDeleteAsk} >Delete current preset</Button>
                        </Col>
                        <ReactTooltip />

                    </Row>
                </div>
                <Tabs selectedIndex={this.props.tabIndex ? this.props.tabIndex.index : 0} onSelect={index => this.props.tabIndexAction({ index })} >
                    <TabList>
                        <Tab>Summary</Tab>
                        <Tab>SNV/indel</Tab>
                        <Tab>CNA/LOH</Tab>
                        <Tab>Rearrangements</Tab>
                        <Tab>Translocations</Tab>
                        {/* <Tab disabled>IGV</Tab> */}
                        <Tab>IGV</Tab>
                    </TabList>
                    <TabPanel>
                        <Summary
                            user={this.props.user}
                            run={this.props.run}
                            samples={samples}
                            qc={QC}
                            analyses={this.analyses}
                            sample={this.props.sample}
                            cov={this.state.COV ? this.state.COV : null}
                        // cov={this.state.COV ? this.state.COV[this.props.sample] : null}
                        />
                    </TabPanel>
                    <TabPanel>
                        <SNV
                            data={SNV1}
                            data2={SNV2}
                            waitingForComment={this.state.waitingForComment} />
                    </TabPanel>
                    <TabPanel>
                        <CNV
                            tab={CNVtab}
                            data={CNVplot}
                            /*waitingForComment={this.state.waitingForComment}*/ />
                    </TabPanel>
                    <TabPanel>
                        <Rearrangements
                            data={rearrangements}
                            info={infoRearTrans} />
                    </TabPanel>
                    <TabPanel>
                        <Translocations
                            data={translocations}
                            info={infoRearTrans} />
                    </TabPanel>
                    <TabPanel>
                        <IGV
                            user={this.props.user}
                            run={this.props.run}
                            sample={this.props.sample}
                            samples={samples}
                        />
                    </TabPanel>
                </Tabs>
            </div >
        )
    }
}

//Passing global state as props (redux)
const mapStateToProps = (state) => {
    return {
        preset: state.preset,
        project: state.project,
        run: state.run,
        runs: state.runs,
        sample: state.sample,
        user: state.user,
        presetList: state.presetList,
        globalFilters: state.globalFilters,
        userTables: state.userTables,
        tabIndex: state.tabIndex,
        backend: state.backend
    }
}
const mapDispatchToProps = { presetAction, projectAction, runAction, sampleAction, userAction, filterAction, presetListAction, updateCommentRemote, addAlert, tabIndexAction } // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 
export default connect(mapStateToProps, mapDispatchToProps)(Results)