import React from 'react'
import SearchTable from './SearchTable'
import { connect } from 'react-redux'
import { filterAction } from '../../actions/action'
import { addAlert } from '../../actions/alerts';
import { updateCommentLocal, updateCommentRemote } from '../../actions/comment';
import { Row, Col } from 'react-bootstrap'

class SNV extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: 'SNV2',
            dispNote: false
        }
        this.multiselectRef = React.createRef()
    }

    render() {

        return (
            this.props.data === null ?
                <h1> select a sample </h1> :
                !this.props.data ?
                    <h1> SNV data not available for this sample </h1> :
                    <div>
                        <Row>
                            <Col>
                                <SearchTable
                                    data={this.props.data2}
                                    run={this.props.run}
                                    sample={this.props.sample}
                                    user={this.props.user}
                                    table={"SNV2"}
                                />
                            </Col>
                        </Row>
                    </div >
        )
    }
}

//Passing global state as props (redux)
const mapStateToProps = (state) => {
    return {
        preset: state.preset,
        run: state.run,
        sample: state.sample,
        user: state.user,
        globalFilters: state.globalFilters,
        columns: state.columns,
        commentLocal: state.comment.commentLocal,
        commentRemote: state.comment.commentRemote
    }
}
const mapDispatchToProps = { filterAction, addAlert, updateCommentLocal, updateCommentRemote } // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 
export default connect(mapStateToProps, mapDispatchToProps)(SNV)