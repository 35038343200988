import React from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { presetAction } from '../../actions/action'

class Selection extends React.Component {

    handleChange = x => {
        this.props.projectFun(x.value)
    }

    render() {
        var placeholder = this.props.choices == null ? 'No options' : null
        // current value is stored in a global variable passed here as a prop

        var choices = this.props.choices ? this.props.choices : [{ label: '', value: '' }]

        //var val = (placeholder | this.props.choices == null) ? null : choices[choices.map(x => x.value == this.props.val).findIndex(k => k == 1)]
        placeholder = choices.length > 0 ? 'Please select an item' : 'No items available'
        var default_val = this.props.preset === true ? choices[0] : null
        var current_val = this.props.value.value !== null & choices.map(x => x.value).includes(this.props.value.value) ? this.props.value : null

        if (this.props.preset & (choices.length === 1 | this.props.value.value === null)) {
            current_val = choices[0]
            this.props.presetAction(current_val.value)
        }

        return (
            <Select
                options={choices}
                onChange={this.handleChange}
                placeholder={placeholder}
                defaultValue={default_val}
                value={current_val}
            />
        )
    }
}

//Passing global state as props
const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}
const mapDispatchToProps = { presetAction } // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 

export default connect(mapStateToProps, mapDispatchToProps)(Selection)