//AntiBodyCoverage
import * as d3 from 'd3';
import { CHR_LEN, CUMULATIVE_LEN_START, CUMULATIVE_LEN_END, BAND_COLORS } from './data/d3-constants';
import './CSS.css'
import cloneDeep from 'lodash/cloneDeep'

export class Plotting {

    constructor(args) {
        this.width = 1600;
        this.height = 910;
        this.margin = {
            top: 20,
            right: 30,
            bottom: 40,
            left: 20
        }

        //this.vaf = args[0]
        this.cytobands = args[0]
        this.cnr = cloneDeep(args[1])
        this.cnv_kit_full = cloneDeep(args[2])
        this.cnv_kit = cloneDeep(args[2].filter(x => x.loh === ''))
        this.vaf = args[3]
        this.select = args[4]
        this.chrSelect = args[5]
        this.global = args[6]
        this.diploidy = args[7]
        this.seg = args[8]

        if (this.diploidy) {
            this.cnr.forEach(x => x.log2 = Number(x.log2) + Number(this.diploidy) + '')
            this.cnv_kit_full.forEach(x => x.log2 = Number(x.log2) + Number(this.diploidy) + '')
            this.cnv_kit.forEach(x => x.log2 = Number(x.log2) + Number(this.diploidy) + '')
            //this.chrSelect.forEach(x => x.log2 = Number(x.log2) + this.diploidy + '')
        }

        // viewBox fill in entire window by widths and resizes with the window... really cool
        this.svg = d3
            .select('.view-port')
            .append('svg')
            .attr("viewBox", `-30 0 ${this.width + this.margin.right + this.margin.left} ${this.height + this.margin.top + this.margin.bottom}`)
            .attr("preserveAspectRatio", "xMinYMin meet")
            .classed("svg-content", true)
            .attr("id", "CNVplot")
            .append('g')
            .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

        //const last = Object.keys(CUMULATIVE_LEN_END).length;
        const chrs = Object.keys(CHR_LEN);
        this.x0 = [0, CUMULATIVE_LEN_END['Y']];
        this.y0 = [800, 0];

        this.full_x_scale = d3.scaleLinear()
            .domain(this.x0)
            .range([this.margin.left, this.width - this.margin.right]);
        this.full_y_scale = d3.scaleLinear()
            .domain(this.y0)
            .range([this.height - this.margin.bottom, this.margin.top]);

        //removing chromosome from cytobands data that we won't be plotting
        this.cyto_clean = this.cytobands.filter(d => {
            return (chrs.includes(d.CHR));
            // return(["1","2","3","22"].includes(d.CHR));
        });

        // UPPER PLOTS ------------------------------------------------------------------------------------------------------------
        // LOG 2 ------------------------------------------------------------------------------------------------------------
        // LOG 2 ------------------------------------------------------------------------------------------------------------
        // LOG 2 ------------------------------------------------------------------------------------------------------------
        // Plot name
        this.svg
            .append('text')
            .attr('class', `plot-label`)
            .text('Log2 ratio')
            .attr('x', -140)
            .attr('font-size', 20)
            .attr('y', -30)
            .attr('transform', "rotate(270)")

        //Draw lines between chromosomes
        this.svg
            .append('g')
            .attr('class', 'chromosomes')
            .selectAll('.chr-lines')
            .data([...Object.entries(CUMULATIVE_LEN_START), ['Y', CUMULATIVE_LEN_END['Y']]])
            .enter()
            .append('rect')
            .attr('class', `chr-lines`)
            .attr('x', d => this.full_x_scale(d[1]) - 2)
            .attr('width', 2)
            .attr('y', 25)
            .attr('height', this.full_y_scale(108))
            .style('fill', 'black')
            .style('fill-opacity', 0.5);

        //Tag individual chromosomes
        this.svg
            .append('g')
            .attr('class', 'chromosomes')
            .selectAll('.chr-label')
            .data(Object.entries(CUMULATIVE_LEN_START))
            .enter()
            .append('text')
            .attr('class', `chr-label`)
            .text(d => d[0])
            // .attr('x', d => this.full_x_scale(d[1]) + 3)
            .attr('x', d => this.full_x_scale(d[1]) + 10)
            .attr('width', 2)
            .attr('y', 20)
            .style('fill', 'black')
            .style('fill-opacity', 0.5)
            .on('click', d => { this.selectChr(d[0]) })
            .style("text-anchor", "middle")


        // Y scale for CN plot
        // Y axis marks
        this.cnv_mid = 85
        this.log2_size = 30
        this.svg
            .append('g')
            .attr('class', 'chromosomes')
            .selectAll('.chr-y-lines')
            .data(Object.entries([-2, -1, 0, 1, 2]))
            .enter()
            .append('rect')
            .attr('class', `chr-y-lines`)
            .attr('x', d => 14)
            .attr('width', 10)
            .attr('y', d => this.cnv_mid + Number(d[1]) * this.log2_size + 1)
            .attr('height', 2)
            .style('fill', 'black')
            .style('fill-opacity', 0.5);

        //Gray horizontal helper lines
        this.svg
            .append('g')
            .attr('class', 'chromosomes')
            .selectAll('.chr-y-lines')
            .data(Object.entries([-2, -1, 0, 1, 2]))
            .enter()
            .append('rect')
            .attr('class', `chr-y-lines`)
            .attr('x', d => 14)
            .attr('width', 1555)
            .attr('y', d => this.cnv_mid + Number(d[1]) * this.log2_size + 1)
            .attr('height', 2)
            .style('fill', 'black')
            .style('fill-opacity', 0.05);

        //log2 label values 
        this.svg
            .append('g')
            .attr('class', 'chromosomes')
            .selectAll('.chr-label')
            .data(Object.entries([-2, -1, 0, 1, 2]))
            .enter()
            .append('text')
            .attr('class', `chr-label`)
            .text(d => d[1])
            .attr('x', -10)
            .attr('width', 2)
            .attr('y', d => this.cnv_mid - Number(d[1]) * this.log2_size + 1)
            .style('fill', 'black')
            .style('fill-opacity', 0.5)
            .style("text-anchor", "middle")

        // COPY NUMBER LOG 2 POINTS AND SEGMENTS ------------------------------------------------------------------------------------------------------------
        const cnvkit_events = this.svg
            .append('g')
            .attr('class', 'cnvkit');

        cnvkit_events
            .selectAll('.cnvkit-cnr-circles')
            .data(this.cnr)
            .enter()
            .filter(function (d) { return Math.abs(d.log2) < 2 })
            .append('circle')
            .attr('class', 'cnvkit-events-circles')
            .attr("cx", d => this.full_x_scale(d.end) + this.full_x_scale(CUMULATIVE_LEN_START[d.chromosome]) - this.full_x_scale(0))
            .attr("cy", d => this.cnv_mid - Number(d.log2) * this.log2_size + 1)
            .attr("r", d => 2 * d.weight)
            .attr("fill", d => { return "grey" })
            .style('fill-opacity', 0.3)

        // Normal Segments
        if (this.seg) {
            cnvkit_events
                .selectAll('.cnvkit-events-lines')
                .data(this.cnv_kit)
                .enter()
                .filter(function (d) { return Math.abs(d.log2) < 2 })
                .append('line')
                .attr('class', `cnvkit-events-lines`)
                .attr('x1', d => this.full_x_scale(d.start) + this.full_x_scale(CUMULATIVE_LEN_START[d.chromosome]) - this.full_x_scale(0))
                .attr('x2', d => this.full_x_scale(d.end) + this.full_x_scale(CUMULATIVE_LEN_START[d.chromosome]) - this.full_x_scale(0))
                .attr('y1', d => this.cnv_mid - Number(d.log2) * this.log2_size + 1)
                .attr('y2', d => this.cnv_mid - Number(d.log2) * this.log2_size + 1)
                .attr("stroke-width", 4)
                .style("stroke-linecap", "round")
                .attr("stroke", d => { return "orange" })

            // Outlier segments
            cnvkit_events
                .selectAll('.cnvkit-events-lines-out')
                .data(this.cnv_kit)
                .enter()
                .filter(function (d) { return Math.abs(d.log2) >= 2 })
                .append('line')
                .attr('class', `cnvkit-events-lines-out`)
                .attr('x1', d => this.full_x_scale(d.start) + this.full_x_scale(CUMULATIVE_LEN_START[d.chromosome]) - this.full_x_scale(0))
                .attr('x2', d => this.full_x_scale(d.end) + this.full_x_scale(CUMULATIVE_LEN_START[d.chromosome]) - this.full_x_scale(0))
                .attr('y1', d => this.cnv_mid - (Math.abs(d.log2) / d.log2) * 2 * this.log2_size + 1)
                .attr('y2', d => this.cnv_mid - (Math.abs(d.log2) / d.log2) * 2 * this.log2_size + 1)
                .attr("stroke-width", 4)
                .style("stroke-linecap", "round")
                .attr("stroke", d => { return "red" })
        }

        this.cnv_end = 80 + this.cnv_mid

        // BAF PLOT ------------------------------------------------------------------------------------------------------------
        // BAF PLOT ------------------------------------------------------------------------------------------------------------
        // BAF PLOT ------------------------------------------------------------------------------------------------------------
        // Plot name
        this.svg
            .append('text')
            .attr('class', `plot-label`)
            .text('BAF plot')
            .attr('y', -30)
            .attr('font-size', 20)
            .attr('x', -this.cnv_end - 130)
            .attr('transform', "rotate(270)")

        // chromosome separating vertical lines
        this.svg
            .append('g')
            .attr('class', 'chromosomes')
            .selectAll('.vaf-lines')
            .data([...Object.entries(CUMULATIVE_LEN_START), ['Y', CUMULATIVE_LEN_END['Y']]])
            .enter()
            .append('rect')
            .attr('class', `chr-lines`)
            .attr('x', d => this.full_x_scale(d[1]) - 2)
            .attr('width', 2)
            .attr('y', this.cnv_end)
            .attr('height', this.full_y_scale(124))
            .style('fill', 'black')
            .style('fill-opacity', 0.5);

        //Y axis values lines
        this.svg
            .append('g')
            .attr('class', 'chromosomes')
            .selectAll('.vaf-y-lines')
            .data(Object.entries([0, 0.2, 0.4, 0.6, 0.8, 1]))
            .enter()
            .append('rect')
            .attr('class', `vaf-y-lines`)
            .attr('x', d => 14)
            .attr('width', 10)
            .attr('y', d => this.cnv_end + Number(d[1]) * this.full_y_scale(124))
            .attr('height', 2)
            .style('fill', 'black')
            .style('fill-opacity', 0.5);


        // baf hepler gray lines
        this.svg
            .append('g')
            .attr('class', 'chromosomes')
            .selectAll('.vaf-y-lines')
            .data(Object.entries([0, 0.2, 0.4, 0.6, 0.8, 1]))
            .enter()
            .append('rect')
            .attr('class', `chr-y-lines`)
            .attr('x', d => 14)
            .attr('width', 1555)
            .attr('y', d => this.cnv_end + this.full_y_scale(124) - Number(d[1]) * this.full_y_scale(124))
            .attr('height', 2)
            .style('fill', 'black')
            .style('fill-opacity', 0.05);

        //Y axis labels
        this.svg
            .append('g')
            .attr('class', 'chromosomes')
            .selectAll('.vaf-label')
            .data(Object.entries([0, 0.2, 0.4, 0.6, 0.8, 1]))
            .enter()
            .append('text')
            .attr('class', `vaf-label`)
            .text(d => d[1])
            .attr('x', -10)
            .attr('width', 2)
            .attr('y', d => this.cnv_end + 5 + this.full_y_scale(124) - Number(d[1]) * this.full_y_scale(124))
            .style('fill', 'black')
            .style('fill-opacity', 0.5)
            .style("text-anchor", "middle")


        // VAF points
        const vaf_events = this.svg
            .append('g')
            .attr('class', 'vaf');

        vaf_events
            .selectAll('.vaf-events-circles')
            .data(this.vaf)
            .enter()
            .append('circle')
            .attr('class', 'vaf-events-circles')
            //.attr("wat",d => d)
            .attr("cx", d => this.full_x_scale(d.start) + this.full_x_scale(CUMULATIVE_LEN_START[d.chromosome]) - this.full_x_scale(0))
            .attr("cy", d => this.cnv_end + this.full_y_scale(124) - Number(d.alt_freq) * this.full_y_scale(124) + 1)
            .attr("r", 2)
            .style('fill-opacity', 0.4)
            .attr("fill", d => { return "grey" })

        this.vaf_end = 160 + this.cnv_end

        // LOWER PLOTS BACKGROUND ------------------------------------------------------------------------------------------------------------
        this.svg
            .append('rect')
            .attr('class', 'detail')
            .attr('x', -60)
            .attr('width', this.width + 60)
            .attr('y', this.vaf_end)
            .attr('height', 50)
            .style('fill', 'black')
            .style('fill-opacity', 0.05);

        this.brush_end = 60 + this.vaf_end

        this.svg
            .append('rect')
            .attr('class', 'detail')
            .attr('x', -60)
            .attr('width', this.width + 60)
            .attr('y', this.brush_end)
            .attr('height', 650)
            .style('fill', 'black')
            .style('fill-opacity', 0.05);

        this.svg
            .append('text')
            .attr('class', 'empty-text')
            .attr('x', 200)
            .text('Please click on a chromosome number on the top plot')
            .attr('font-size', 50)
            .attr('y', this.brush_end + 300)
            .style('fill', 'black')
            .style('fill-opacity', 1);

        this.context = this.svg.append("g")
            .attr('class', 'context')
            .attr('transform', `translate( ${0}, ${this.brush_end} )`);

        this.focus = this.svg.append("g")
            .attr('class', 'focus')
            .attr('transform', `translate( ${0}, ${300})`)


        // BRUSH TOOL ------------------------------------------------------------------------------------------------------------
        // BRUSH TOOL ------------------------------------------------------------------------------------------------------------
        // BRUSH TOOL ------------------------------------------------------------------------------------------------------------
        this.brush = d3.brushX().extent([[10, -64], [this.width - 20, -8]])
            .on('brush', brushed);

        // TO DO: make this part of the code nicer
        // moved brush inside constructor becasause otherwise there was no context
        // the scales are initialized and assigned to variables so the brushed function
        // can see them, otherwise the scales are assigned in selectChr function
        // which runs always prior to brush
        this.chr_x_scale = d3.scaleLinear()
        this.chr_x_scale_zoom = d3.scaleLinear();

        this.xAxis = d3.axisBottom(this.chr_x_scale);
        this.xAxis_zoom = d3.axisTop(this.chr_x_scale_zoom);


        let chr_x_scale = this.chr_x_scale;
        let chr_x_scale_zoom = this.chr_x_scale_zoom;
        let focus = this.focus;
        let xAxis_zoom = this.xAxis_zoom;

        this.bands_focus = 110
        this.cytoname = this.bands_focus + 20
        let cytoname = this.cytoname
        let chrSelect = args[5]
        let segment = this.seg

        function brushed() {
            const s = d3.event.selection || chr_x_scale.domain();
            const t = s.map(chr_x_scale.invert, chr_x_scale_zoom);
            //this.chrSelect
            chrSelect({ pos: t })
            chr_x_scale_zoom.domain(t);
            focus.select('.cytobands-container-focus')
                .selectAll('.cyto-bands-detail')
                .attr('x', d => chr_x_scale_zoom(d.from) > 20 ? chr_x_scale_zoom(d.from) : 20)
                .attr('width', d => chr_x_scale_zoom(d.from) > 20 ? chr_x_scale_zoom(d.to) - chr_x_scale_zoom(d.from) : chr_x_scale_zoom(d.to) - 20)
            focus.selectAll('.cytoname-f')
                .attr("transform", d => `translate(${chr_x_scale_zoom(d["from"]) > 20 ? chr_x_scale_zoom(d["from"]) : -100},${cytoname})rotate(90)`);
            focus.select(".axis--x").call(xAxis_zoom);
            focus.selectAll('.cnvkit-events-vaf')
                .attr('cx', d => chr_x_scale_zoom(d.end) > 20 ? chr_x_scale_zoom(d.end) : -100);
            focus.selectAll('.focused-cnvkit-cnr-circles')
                .attr('cx', d => chr_x_scale_zoom(d.end) > 20 ? chr_x_scale_zoom((Number(d.start) + Number(d.end)) / 2) : -100);
            if (segment) {
                focus.selectAll('.focused-cnvkit-segments')
                    .attr('x1', d => chr_x_scale_zoom(d.start) > 20 ? chr_x_scale_zoom(d.start) : 20)
                    .attr('x2', d => chr_x_scale_zoom(d.end) > 20 ? chr_x_scale_zoom(d.end) : 20)
                    .style("stroke-linecap", d => chr_x_scale_zoom(d.end) <= 20 & chr_x_scale_zoom(d.start) <= 20 ? 'unset' : 'round')
                focus.selectAll('.focused-cnvkit-segments-vaf')
                    .attr('x1', d => chr_x_scale_zoom(d.start) > 20 ? chr_x_scale_zoom(d.start) : 20)
                    .attr('x2', d => chr_x_scale_zoom(d.end) > 20 ? chr_x_scale_zoom(d.end) : 20)
                    .style("stroke-linecap", d => chr_x_scale_zoom(d.end) <= 20 & chr_x_scale_zoom(d.start) <= 20 ? 'unset' : 'round')
                focus.selectAll('.focused-cnvkit-segments-out')
                    .attr('x1', d => chr_x_scale_zoom(d.start) > 20 ? chr_x_scale_zoom(d.start) : 20)
                    .attr('x2', d => chr_x_scale_zoom(d.end) > 20 ? chr_x_scale_zoom(d.end) : 20);
            }
            focus.selectAll('.focused-vertical-helper')
                .attr('x', d => chr_x_scale_zoom(d[1]) > 20 ? chr_x_scale_zoom(d[1]) : -100)
            focus.selectAll('.focused-par')
                .attr('x', d => chr_x_scale_zoom(10001))
                .attr('width', d => chr_x_scale_zoom(2781479) - chr_x_scale_zoom(10001))
            focus.selectAll('.focused-par-2-x')
                .attr('x', d => chr_x_scale_zoom(155701383))
                .attr('width', d => chr_x_scale_zoom(156030895) - chr_x_scale_zoom(155701383))
            focus.selectAll('.focused-par-2-y')
                .attr('x', d => chr_x_scale_zoom(56887903))
                .attr('width', d => chr_x_scale_zoom(57217415) - chr_x_scale_zoom(56887903))
        }

        if (this.global) {
            this.selectChr(this.global.row.chromosome)
            if (this.global.row.start !== undefined & this.global.row.end !== undefined) {
                var start = this.global.row.start.replace(/,/g, '')
                var end = this.global.row.end.replace(/,/g, '')
                var margin = this.chr_x_scale(end - start) * 0.05
                d3.select(".brush").call(this.brush.move, [this.chr_x_scale(start) - margin, this.chr_x_scale(end) + margin]);
            }
        }

        // Selecting rectangles for individual chromosomes
        this.svg
            .append('g')
            .attr('class', 'chromosomes')
            .selectAll('.chr-selection')
            .data(Object.entries(CUMULATIVE_LEN_START))
            .enter()
            .append("rect")
            .attr('class', `chr-selection`)
            .attr('x', d => this.full_x_scale(d[1]))
            .attr('y', 2)
            .attr('width', d => this.full_x_scale(CHR_LEN[d[0]]) - 20)
            .attr('height', 320)
            .style("stroke", "none")
            .style('fill', 'red')
            .style('fill-opacity', 0)
            .on('click', d => { this.selectChr(d[0]) })
            .style("text-anchor", "middle")
    }

    selectChr(chr) {
        //clean up
        d3.selectAll(".empty-text").remove();
        this.context.selectAll(".brush").remove();
        d3.selectAll(".graph-el").remove();
        d3.selectAll(".axis--x").remove();

        this.chrSelect({ chromosome: chr })
        this.chr_x_scale.domain([0, CHR_LEN[chr]])
            .range([this.margin.left, this.width - this.margin.right]);

        this.chr_x_scale_zoom.domain([0, CHR_LEN[chr]])
            .range([this.margin.left, this.width - this.margin.right]);

        //scale for a single chromosome
        let cyto_clean_chr = this.cyto_clean.filter(d => {
            return ([chr].includes(d.CHR))
        });

        this.svg
            .append("rect")
            .attr('class', `graph-el`)
            .attr('x', this.full_x_scale(CUMULATIVE_LEN_START[chr]))
            .attr('y', 2)
            .attr('width', this.full_x_scale(CHR_LEN[chr]) - 22)
            .attr('height', 320)
            .style("stroke", "yellow")
            .attr("stroke-width", 0.9)
            .style('fill', "yellow")
            .on('click', d => { this.selectChr(chr) })
            .style("cursor", "alias")
            .style('fill-opacity', 0.1)

        let cnv_kit_chr = this.cnv_kit.filter(d => {
            return ([chr].includes(d.chromosome))
        })

        let cnv_kit_chr_full = this.cnv_kit_full.filter(d => {
            return ([chr].includes(d.chromosome))
        })

        let cnr_chr = this.cnr.filter(d => {
            return ([chr].includes(d.chromosome))
        })

        let vaf_chr = this.vaf.filter(d => {
            return ([chr].includes(d.chromosome))
        })
        // LOWER PLOTS ------------------------------------------------------------------------------------------------------------
        // LOWER PLOTS ------------------------------------------------------------------------------------------------------------
        // LOWER PLOTS ------------------------------------------------------------------------------------------------------------
        // CYTOBANDS ------------------------------------------------------------------------------------------------------------
        // CYTOBANDS ------------------------------------------------------------------------------------------------------------
        // CYTOBANDS ------------------------------------------------------------------------------------------------------------
        this.context
            .append('g')
            .attr('class', 'cytobands-container-context')
            .selectAll('.cyto-bands-detail')
            .data(cyto_clean_chr)
            .enter()
            .append('rect')
            .attr('class', `cyto-bands-detail graph-el`)
            .attr('x', d => this.chr_x_scale(d.from))
            .attr('width', d => this.chr_x_scale(d.to) - this.chr_x_scale(d.from))
            .attr('y', this.full_y_scale(0) - 68)
            .attr('height', 14)
            .style('fill', d => BAND_COLORS[d.style])
            .style('fill-opacity', 0.9);

        // Bands for the selected in focus area.
        this.focus
            .append('g')
            .attr('class', 'cytobands-container-focus graph-el')
            .selectAll('.cyto-bands-detail')
            .data(cyto_clean_chr)
            .enter()
            .append('rect')
            .attr('class', `cyto-bands-detail`)
            .attr('x', d => this.chr_x_scale_zoom(d.from))
            .attr('width', d => this.chr_x_scale_zoom(d.to) - this.chr_x_scale_zoom(d.from))
            .attr('y', this.bands_focus)
            .attr('height', 14)
            .style('fill', d => BAND_COLORS[d.style])
            .style('fill-opacity', 0.9);

        this.context
            .append("g")
            .attr("class", "axis axis--x")
            .attr("transform", `translate(0,-30)`)
            .call(this.xAxis);

        this.focus.append("g")
            .attr("class", "axis axis--x")
            .attr("transform", `translate(0,${this.bands_focus})`)
            .call(this.xAxis_zoom);

        // Brush tool added last so that it will be on top 
        var brushNode = this.context.append("g")
            .attr("class", "brush context brush_context")
            .call(this.brush)

        brushNode
            .selectAll(".selection")
            .attr("fill", "none")
            .attr("stroke-width", 3).attr("stroke", "#dd8484")//.attr("stroke-dasharray","3,6")
            .attr("y", -16)
            .attr("height", 82) //height2 + 10)
            .attr("rx", 3)
            .attr("ry", 3)
            .attr("opacity", 1)

        var oldMousedown = brushNode.on('mousedown.brush');

        // and replace it with our custom handler
        brushNode.on('mousedown.brush', function () {
            brushNode.on('mouseup.brush', function () {
                clearHandlers();
            });

            brushNode.on('mousemove.brush', function () {
                clearHandlers();
                oldMousedown.call(this);

            });

            function clearHandlers() {
                brushNode.on('mousemove.brush', null);
                brushNode.on('mouseup.brush', null);
            }
        })


        this.focus.append("g")
            .attr("class", "cytoband-name")
            .selectAll("text")
            .data(cyto_clean_chr)
            .enter()
            .append("text")
            .attr("class", "cytoname-f graph-el")
            .attr("font-size", 9)
            .attr("transform", d => `translate(${this.chr_x_scale_zoom(d["from"])},${this.cytoname})rotate(90)`)
            .text(d => d["arm"]);


        // FOCUS CNV ------------------------------------------------------------------------------------------------------------
        // FOCUS CNV ------------------------------------------------------------------------------------------------------------
        // FOCUS CNV ------------------------------------------------------------------------------------------------------------

        //Focus CNV
        this.focused_cnv_mid = this.bands_focus + 190
        this.focused_log2_size = 60

        //PAR REGION HIGHLIGHT
        this.focus
            .append('rect')
            .attr('class', `focused-par graph-el`)
            .attr('x', d => this.chr_x_scale_zoom(10001))
            .attr('width', d => this.chr_x_scale_zoom(2781479) - this.chr_x_scale_zoom(10001))
            .attr('y', this.focused_cnv_mid - 3.5 * this.focused_log2_size)
            .attr('height', 700)
            .style('fill', '#FFFF00')
            .style("fill-opacity", function (d) {
                if (chr === 'X' | chr === 'Y') { return 0.2 }
                else { return 0 }
            })

        this.focus
            .append('rect')
            .attr('class', `focused-par-2-x graph-el`)
            .attr('x', d => this.chr_x_scale_zoom(155701383))
            .attr('width', d => this.chr_x_scale_zoom(156030895) - this.chr_x_scale_zoom(155701383))
            .attr('y', this.focused_cnv_mid - 3.5 * this.focused_log2_size)
            .attr('height', 700)
            .style('fill', '#FFFF00')
            .style("fill-opacity", function (d) {
                if (chr === 'X') { return 0.2 }
                else { return 0 }
            })

        this.focus
            .append('rect')
            .attr('class', `focused-par-2-y graph-el`)
            .attr('x', d => this.chr_x_scale_zoom(56887903))
            .attr('width', d => this.chr_x_scale_zoom(57217415) - this.chr_x_scale_zoom(56887903))
            .attr('y', this.focused_cnv_mid - 3.5 * this.focused_log2_size)
            .attr('height', 700)
            .style('fill', '#FFFF00')
            .style("fill-opacity", function (d) {
                if (chr === 'Y') { return 0.2 }
                else { return 0 }
            })



        // Plot name
        this.focus
            .append('text')
            .attr('class', `plot-label-log2 graph-el`)
            .text('Log2 ratio')
            .attr('x', -this.focused_cnv_mid - 50)
            .attr('font-size', 20)
            .attr('y', -30)
            .attr('transform', "rotate(270)")

        // Y axis labels 
        this.focus
            .append('g')
            .attr('class', 'chromosomes graph-el')
            .selectAll('.chr-y-lines')
            .data(Object.entries([-2, -1, 0, 1, 2]))
            .enter()
            .append('rect')
            .attr('class', `chr-y-lines`)
            .attr('x', d => 14)
            .attr('width', 10)
            .attr('y', d => this.focused_cnv_mid + Number(d[1]) * this.focused_log2_size)
            .attr('height', 2)
            .style('fill', 'black')
            .style('fill-opacity', 0.5);

        this.focus
            .append('g')
            .attr('class', 'chromosomes graph-el')
            .selectAll('.chr-y-lines')
            .data(Object.entries([-2, -1, 0, 1, 2]))
            .enter()
            .append('rect')
            .attr('class', `chr-y-lines`)
            .attr('x', d => 14)
            .attr('width', this.width)
            .attr('y', d => this.focused_cnv_mid + Number(d[1]) * this.focused_log2_size)
            .attr('height', 2)
            .style('fill', 'black')
            .style('fill-opacity', 0.05);

        //Y limits (-2,2)
        this.focus
            .append('g')
            .attr('class', 'chromosomes graph-el')
            .selectAll('.chr-y-lines')
            .data(Object.entries([-2, 2]))
            .enter()
            .append('rect')
            .attr('class', `chr-y-lines`)
            .attr('x', d => 14)
            .attr('width', this.chr_x_scale_zoom(CHR_LEN[chr]))
            .attr('y', d => this.focused_cnv_mid + Number(d[1]) * this.focused_log2_size)
            .attr('height', 2)
            .style('fill', 'black')
            .style('fill-opacity', 0.5);

        //log2 values 
        this.focus
            .append('g')
            .attr('class', 'chromosomes graph-el')
            .selectAll('.chr-label')
            .data(Object.entries([-2, -1, 0, 1, 2]))
            .enter()
            .append('text')
            .text(d => d[1])
            .attr('x', 0)
            .attr('width', 2)
            .attr('y', d => this.focused_cnv_mid - Number(d[1]) * this.focused_log2_size)
            .style('fill', 'black')
            .style('fill-opacity', 0.5)
            .style("text-anchor", "middle")


        // FOCUSED PLOTS AXES AND HELPER LINES - HAVE TO BE FIRST FOR PROPER DRAWING ORDER (BEFORE CNVKIT EVENTS IS DECLARED) ------------------------------------------------------------------------------------------------------------
        // FOCUSED PLOTS AXES AND HELPER LINES - HAVE TO BE FIRST FOR PROPER DRAWING ORDER (BEFORE CNVKIT EVENTS IS DECLARED) ------------------------------------------------------------------------------------------------------------
        // FOCUSED PLOTS AXES AND HELPER LINES - HAVE TO BE FIRST FOR PROPER DRAWING ORDER (BEFORE CNVKIT EVENTS IS DECLARED) ------------------------------------------------------------------------------------------------------------
        // FOCUSED PLOTS AXES AND HELPER LINES - HAVE TO BE FIRST FOR PROPER DRAWING ORDER (BEFORE CNVKIT EVENTS IS DECLARED) ------------------------------------------------------------------------------------------------------------
        // FOCUSED PLOTS AXES AND HELPER LINES - HAVE TO BE FIRST FOR PROPER DRAWING ORDER (BEFORE CNVKIT EVENTS IS DECLARED) ------------------------------------------------------------------------------------------------------------
        // FOCUSED PLOTS AXES AND HELPER LINES - HAVE TO BE FIRST FOR PROPER DRAWING ORDER (BEFORE CNVKIT EVENTS IS DECLARED) ------------------------------------------------------------------------------------------------------------

        this.focus_vaf_end = this.focused_cnv_mid + 340
        this.vaf_width = 190

        // Y limits (0,1) VAF
        this.focus
            .append('g')
            .attr('class', 'vaf-y-lines-focused-x graph-el')
            .selectAll('.vaf-y-lines-focused-x')
            .data(Object.entries([0, 1]))
            .enter()
            .append('rect')
            .attr('class', `vaf-y-lines-focused-x`)
            .attr('x', 20)
            .attr('width', this.chr_x_scale_zoom(CHR_LEN[chr]))
            .attr('y', d => this.focus_vaf_end - Number(d[1]) * this.vaf_width - 1)
            .attr('height', 2)
            .style('fill', 'black')
            .style('fill-opacity', 0.5)

        //helper lines
        const range = (start, stop, step = 1) => Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)

        this.focus
            .append('g')
            .attr('class', 'chromosomes graph-el')
            .selectAll('.focused-vertical-helper')
            .data(Object.entries(range(0, CHR_LEN[chr], 5000000)))
            .enter()
            .append('rect')
            .attr('class', `focused-vertical-helper graph-el`)
            .attr('x', d => this.chr_x_scale_zoom(d[1]))
            .attr('width', 1)
            .attr('y', d => this.focused_cnv_mid - 2.5 * this.focused_log2_size)
            .attr('height', 700)
            .style('fill', 'black')
            .style('fill-opacity', 0.05)

        this.focus
            .append('g')
            .attr('class', 'chromosomes-focused-helper graph-el')
            .selectAll('.vaf-y-lines-focused-helper')
            .data(Object.entries([0, 0.2, 0.4, 0.6, 0.8, 1]))
            .enter()
            .append('rect')
            .attr('class', `vaf-y-lines`)
            .attr('x', 20)
            .attr('width', this.chr_x_scale_zoom(CHR_LEN[chr]))
            .attr('y', d => this.focus_vaf_end - Number(d[1]) * this.vaf_width)
            .attr('height', 1)
            .style('fill', 'black')
            .style('fill-opacity', 0.1);


        //Vertical line at the start of the plots (cnv)
        this.focus
            .append('g')
            .attr('class', 'chromosomes graph-el')
            .selectAll('.chr-y-lines')
            .data(Object.entries([-2]))
            .enter()
            .append('rect')
            .attr('class', `chr-y-lines`)
            .attr('x', d => 20)
            .attr('width', 2)
            .attr('y', d => this.focused_cnv_mid + Number(d[1]) * this.focused_log2_size)
            .attr('height', d => -2 * Number(d[1]) * this.focused_log2_size)
            .style('fill', 'black')
            .style('fill-opacity', 0.5);


        //Vertical line at the start of the plots (baf)
        this.focus
            .append('g')
            .attr('class', 'chromosomes-baf graph-el')
            .selectAll('.chr-y-lines-baf')
            .data(Object.entries([1]))
            .enter()
            .append('rect')
            .attr('class', `chr-y-lines-baf`)
            .attr('x', 20)
            .attr('width', 2)
            .attr('y', this.focus_vaf_end - this.vaf_width)
            .attr('height', this.vaf_width)
            .style('fill', 'black')
            .style('fill-opacity', 0.5);

        //dashed line in the middle (focus VAF)
        this.focus
            .append('line')
            .attr('class', `vaf-y-lines-dashed graph-el graph-el`)
            .attr('x1', d => 14)
            .attr('x2', this.chr_x_scale_zoom(CHR_LEN[chr]))
            .attr('y1', d => this.focus_vaf_end - Number(0.5) * this.vaf_width)
            .attr('y2', d => this.focus_vaf_end - Number(0.5) * this.vaf_width)
            .attr("stroke-width", 2)
            .style("stroke-dasharray", ("10, 10"))
            .style('stroke-opacity', '0.6')
            .attr("stroke", d => { return "black" })


        // FOCUSED PLOTS CONTENTS ------------------------------------------------------------------------------------------------------------
        // FOCUSED PLOTS CONTENTS ------------------------------------------------------------------------------------------------------------
        // FOCUSED PLOTS CONTENTS ------------------------------------------------------------------------------------------------------------
        // FOCUSED PLOTS CONTENTS ------------------------------------------------------------------------------------------------------------
        // FOCUSED PLOTS CONTENTS ------------------------------------------------------------------------------------------------------------
        // FOCUSED PLOTS CONTENTS ------------------------------------------------------------------------------------------------------------



        // FOCUS CNV LOG2 VALUES ------------------------------------------------------------------------------------------------------------
        // FOCUS CNV LOG2 VALUES ------------------------------------------------------------------------------------------------------------

        const cnvkit_events = this.focus
            .append('g')
            .attr('class', 'focused');

        cnvkit_events
            .selectAll('.focused-cnvkit-cnr-circles')
            .data(cnr_chr)
            .enter()
            .filter(function (d) { return Math.abs(d.log2) < 2 })
            .append('circle')
            .attr('class', 'focused-cnvkit-cnr-circles graph-el')
            .attr("cx", d => this.chr_x_scale_zoom(d.end))
            .attr("cy", d => this.focused_cnv_mid - Number(d.log2) * this.focused_log2_size + 1.5 * d.weight)
            .attr("r", d => 3 * d.weight)
            .attr("fill", d => { return "gray" })
            .style('fill-opacity', 0.5)
            .on("mouseover", function (d) {
                Tooltip(this, d)
            })
            .on("mouseout", function (d) {
                d3.select(this).style('stroke', null)
                d3.select('#tooltip').remove()
            })

        // tooltip function

        var Tooltip = (that, d) => {
            if (that.tagName === 'line') {
                that.parentNode.appendChild(that)// brings current segment to the top - no overlapping between segments
            }
            var vaf_seg = that.className.baseVal === "focused-cnvkit-segments-vaf graph-el"
            d3.select(that).style('stroke', 'yellow');
            //that.className.baseVal === "cnvkit-events-vaf graph-el"
            var div = d3.select("body").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0);
            div.transition()
                .duration(1)
                .style("opacity", .9)
                .style("background", "white")
            div.html(() => {
                var start = d.start.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                var end = d.end.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                if (vaf_seg === true) {
                    return "Start: " + start + " End: " + end + " BAF: " + Math.round(d.baf * 100) / 100
                }
                if (isNaN(Math.round(d.log2 * 100) / 100)) {
                    return "Position: " + start + " BAF: " + d.alt_freq + " Depth: " + d.depth
                } else {
                    return "Start: " + start + " End: " + end + " Log2: " + Math.round(d.log2 * 100) / 100 + " Depth: " + d.depth
                }
            })
                .attr('id', 'tooltip')
                .style("left", (d3.event.pageX) + "px")
                .style("top", (d3.event.pageY - 28) + "px")
                .style("position", 'absolute')
                .style('text-align', 'center')
                .style('padding', '2px')
                .style('font', '12px sans-serif')
                .style('background', 'lightsteelblue')
                .style('border', '0px')
                .style('border-radius', '8px')
                .style('pointer-events', 'none')
        }
        // focused segments
        if (this.seg) {
            cnvkit_events
                .selectAll('.focused-cnvkit-segments')
                .data(cnv_kit_chr_full)
                .enter()
                .filter(function (d) { return Math.abs(d.log2) < 2 })
                .filter(function (d) { return d.loh === '' })
                .append('line')
                .attr('class', `focused-cnvkit-segments graph-el`)
                .attr('x1', d => this.chr_x_scale_zoom(d.start))
                .attr('x2', d => this.chr_x_scale_zoom(d.end))
                .attr('y1', d => this.focused_cnv_mid - Number(d.log2) * this.focused_log2_size)
                .attr('y2', d => this.focused_cnv_mid - Number(d.log2) * this.focused_log2_size)
                .attr("stroke-width", 5)
                .attr("stroke", d => { return "orange" })
                .style("stroke-linecap", "round")
                .style("cursor", "pointer")
                .on("mouseover", function (d) {
                    Tooltip(this, d)
                })
                .on("mouseout", function (d) {
                    d3.select(this).style('stroke', 'orange')
                    d3.select('#tooltip').remove()
                }).on("click", (d) => {
                    this.select(d)
                })


            // outlier segments
            cnvkit_events
                .selectAll('.focused-cnvkit-segments-out')
                .data(cnv_kit_chr)
                .enter()
                .filter(function (d) { return Math.abs(d.log2) >= 2 })
                .append('line')
                .attr('class', `focused-cnvkit-segments-out graph-el`)
                .attr('x1', d => this.chr_x_scale_zoom(d.start))
                .attr('x2', d => this.chr_x_scale_zoom(d.end))
                .attr('y1', d => this.focused_cnv_mid - d.log2 / Math.abs(d.log2) * 2 * this.focused_log2_size)
                .attr('y2', d => this.focused_cnv_mid - d.log2 / Math.abs(d.log2) * 2 * this.focused_log2_size)
                .attr("stroke-width", 5)
                .style("stroke-linecap", "round")
                .attr("stroke", d => { return "red" })
                .style("cursor", "pointer")
                .on("mouseover", function (d) {
                    Tooltip(this, d)
                })
                .on("mouseout", function (d) {
                    d3.select(this).style('stroke', 'red')
                    d3.select('#tooltip').remove()
                }).on("click", (d) => {
                    this.select(d)
                })
        }

        // FOCUS BAF PLOT ------------------------------------------------------------------------------------------------------------
        // FOCUS BAF PLOT ------------------------------------------------------------------------------------------------------------
        // FOCUS BAF PLOT ------------------------------------------------------------------------------------------------------------

        // Plot name
        this.focus
            .append('text')
            .attr('class', `plot-label graph-el`)
            .text('BAF')
            .attr('x', -this.focused_cnv_mid - 265)
            .attr('font-size', 20)
            .attr('y', -30)
            .attr('transform', "rotate(270)")

        //Y axis values
        this.focus
            .append('g')
            .attr('class', 'chromosomes-focused graph-el')
            .selectAll('.vaf-y-lines-focused')
            .data(Object.entries([0, 0.2, 0.4, 0.6, 0.8, 1]))
            .enter()
            .append('rect')
            .attr('class', `vaf-y-lines`)
            .attr('x', d => 14)
            .attr('width', 10)
            .attr('y', d => this.focus_vaf_end - Number(d[1]) * this.vaf_width - 1)
            .attr('height', 2)
            .style('fill', 'black')
            .style('fill-opacity', 0.5);



        //Y axis labels
        this.focus
            .append('g')
            .attr('class', 'chromosomes-focused graph-el')
            .selectAll('.vaf-label')
            .data(Object.entries([0, 0.2, 0.4, 0.6, 0.8, 1]))
            .enter()
            .append('text')
            .attr('class', `vaf-label`)
            .text(d => d[1])
            .attr('x', -10)
            .attr('width', 2)
            .attr('y', d => this.focus_vaf_end - Number(d[1]) * this.vaf_width)
            .style('fill', 'black')
            .style('fill-opacity', 0.5)
            .style("text-anchor", "middle")

        // VARIANTS POINTS----------------------------------------------------------------------------------------------------------
        cnvkit_events
            .selectAll('.cnvkit-events-vaf')
            .data(vaf_chr)
            .enter()
            .append('circle')
            .attr('class', 'cnvkit-events-vaf graph-el')
            .attr("cx", d => this.chr_x_scale_zoom(d.end))
            .attr("cy", d => this.focus_vaf_end - Number(d.alt_freq) * this.vaf_width)
            .attr("r", 4)
            .style('fill-opacity', 0.7)
            .attr("fill", d => { return "gray" })
            .on("mouseover", function (d) {
                Tooltip(this, d)
            })
            .on("mouseout", function (d) {
                d3.select(this).style('stroke', null)
                d3.select('#tooltip').remove()
            })

        // VARIANTS SEGMENTS----------------------------------------------------------------------------------------------------------
        if (this.seg) {
            cnvkit_events
                .selectAll('.focused-cnvkit-segments-vaf')
                .data(cnv_kit_chr_full)
                .enter()
                .filter((d, i, a) => {
                    if (a[i + 1] === undefined) {
                        return d
                    }
                    if (a[i + 1].__data__.loh !== '' & d.loh === '') {
                        return null
                    } else {
                        return d
                    }
                })
                .append('line')
                .attr('class', `focused-cnvkit-segments-vaf graph-el`)
                .attr('x1', d => this.chr_x_scale_zoom(d.start))
                .attr('x2', d => this.chr_x_scale_zoom(d.end))
                .attr('y1', d => this.focus_vaf_end - Number(d.baf) * this.vaf_width)
                .attr('y2', d => this.focus_vaf_end - Number(d.baf) * this.vaf_width)
                .attr("stroke-width", 5)
                .style("cursor", "pointer")
                .attr("stroke", d => {
                    if (d.loh !== '') {
                        return "#F08080"
                    } else {
                        return "orange"
                    }
                })
                .style("stroke-linecap", "round")
                .on("mouseover", function (d) {
                    Tooltip(this, d)
                })
                .on("mouseout", function (d) {
                    d3.select(this).style("stroke", d => {
                        if (d.loh !== '') {
                            return "#F08080"
                        } else {
                            return "orange"
                        }
                    })
                    d3.select('#tooltip').remove()
                })
                .on("click", (d) => {
                    this.select(d)
                })
        }

    }

    destroy() {
        this.svg.remove();
        d3.select('.view-port').html("");
    }

    update(e) {
        this.selectChr(e.row.chromosome)
        if (e.row.end !== undefined & e.row.start !== undefined) {
            var start = e.row.start.replace(/,/g, '')
            var end = e.row.end.replace(/,/g, '')
            var margin = this.chr_x_scale(end - start) * 0.05
            d3.select(".brush").call(this.brush.move, [this.chr_x_scale(start) - margin, this.chr_x_scale(end) + margin]);

            if (e.row.loh === '') { //normal segment
                d3.selectAll('.focused-cnvkit-segments')
                    .filter(function (d, i) {
                        return d.start === start & d.end === end
                    })
                    .attr('stroke', 'yellow')

                const t = d3.timer(elapsed => {
                    if (elapsed >= 3000) {
                        d3.selectAll('.focused-cnvkit-segments')
                            .filter(function (d, i) {
                                return d.start === start & d.end === end
                            })
                            .attr('stroke', 'orange')
                        t.stop() //stops timer
                    }
                })
            } else { //variant segment
                d3.selectAll('.focused-cnvkit-segments-vaf')
                    .filter(function (d, i) {
                        return d.start === start & d.end === end
                    })
                    .attr('stroke', 'yellow')

                const t = d3.timer(elapsed => {
                    if (elapsed >= 3000) {
                        d3.selectAll('.focused-cnvkit-segments-vaf')
                            .filter(function (d, i) {
                                return d.start === start & d.end === end
                            })
                            .attr('stroke', '#F08080')
                        t.stop() //stops timer
                    }
                })
            }
        }
    }
}