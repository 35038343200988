import React from 'react'
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"
import SearchTable from './SearchTable'
import { connect } from 'react-redux'
import { Row, Col, Breadcrumb } from 'react-bootstrap'
import axios from 'axios'
import {addAlert} from '../../actions/alerts'

class Summary extends React.Component {

    constructor(props) {
        super(props)
        this.link = React.createRef()
    }


    createMarkup(html) {
        return { __html: html };
    }

    underlineOn = (e) => {
        e.target.style.textDecoration = 'underline'
    }

    underlineOff = (e) => {
        e.target.style.textDecoration = ''
    }
    

    handleDownloadFirst = () => {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = 'https://' + window.location.hostname + '/api/report/first/'


        fetch(file, {
            method: 'POST',
            headers: {
              Authorization: axios.defaults.headers.authorization,
              user: this.props.user,
              run:  this.props.run
            },
          })
            
      .then((response) => {
        // Assuming the response is a file to download
        response.blob().then((blob) => {
          const url = URL.createObjectURL(blob);
          window.open(url,'_blank');
        });
      })
      .catch((error) => {
        this.props.addAlert({ message: 'QC report file not found' });
    });
    }


    handleDownloadSecond = () => {
        let anchor = document.createElement("b");
        document.body.appendChild(anchor);
        let file = 'https://' + window.location.hostname + '/api/report/second/'


        fetch(file, {
            method: 'POST',
            headers: {
              Authorization: axios.defaults.headers.authorization,
              user: this.props.user,
              run:  this.props.run
            },
          })
            
      .then((response) => {
        // Assuming the response is a file to download
        response.blob().then((blob) => {
          const url = URL.createObjectURL(blob);
          window.open(url,'_blank');
        });
      })
      .catch((error) => {
        this.props.addAlert({ message: 'QC report file not found' });
    });
    }

    handleReport = () => {
        axios.post('report', { run: this.props.run })
            .then(res => {
                //Create a Blob from the PDF Stream
                let responseHtml = res.data
                console.log(responseHtml, "Monitoring");
                //open the new window and write your HTML to it
                var myWindow = window.open("", '_blank');
                myWindow.document.write(responseHtml);//responseHtml.map(x => x.includes('es in the bam file (millions)</td>')).reduce(
                //     (out, bool, index) => bool ? out.concat(index) : out, 
                //     []
                //   )
                //responseHtml.join('\n').length
                //responseHtml.map(x => x.length).forEach( num => {sum += num;})
            })
    }

    render() {
        //var qc = this.props.qc ? this.props.qc[Object.keys(this.props.qc)[0]] : null
        var data = this.props.analyses
        if (data) {
            const median = arr => {
                const mid = Math.floor(arr.length / 2),
                    nums = [...arr].sort((a, b) => a - b);
                return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
            };

            var coverages = this.props.samples.map(x => this.props.cov[x.value])
            var Coverage_means = ![undefined, null].includes(coverages[0]) ? coverages.map(x => x !== false ? x.map(y => parseFloat(y.Coverage_mean)).reduce((q, w) => q + w, 0) / x.length : NaN) : NaN
            var Coverage_medians = ![undefined, null].includes(coverages[0]) ? coverages.map(x => x !== false ? median(x.map(y => parseFloat(y.Coverage_median))) : NaN) : NaN
            for (var i = 0; i < Coverage_means.length; i++) {
                data[i].Coverage_mean = Coverage_means[i].toFixed(2)
                data[i].Coverage_median = Coverage_medians[i].toFixed(2)
            }
        }

        var columns = ['Sample', 'SNV', 'CNV', 'translocations/rearrangements', 'Coverage_mean', 'Coverage_median'].map(x => [{
            Header: x === 'Sample' ? () => (
                <div style={{ textAlign: "left" }}>Sample</div>
            ) : x, accessor: x, Cell: row => (
                <div style={{ textAlign: x !== 'Sample' ? "center" : null }}>{row.value}</div>
            ),
            sortMethod: (a, b) => {
                if (['Coverage_mean', 'Coverage_median'].includes(x)) {
                    if (a === 'NaN') a = '0'
                    if (b === 'NaN') b = '0'
                    return Number(a.replace(/,/g, '')) > Number(b.replace(/,/g, '')) ? 1 : -1
                }
            }
        }][0])
        var trigger = Math.random()
        var summary_table = !data ? <div>
            <h2>Loading summary table ... <div className="lds-facebook"><div></div><div></div><div></div></div></h2>

        </div> : <div>
            <ReactTable
                style={{ 'maxHeight': '400px' }}
                pageSize={this.props.samples.length}
                columns={columns}
                data={data}
                showPagination={false}
            />
        </div>

        if (this.props.run != null) {
            var nsamples = this.props.samples.length
            // var storage_path = '/Users/tomas/Dropbox/work/apps/lynx-app/storage/'
            // var QCreport_path = storage_path + this.props.user + '/' + this.props.run + '/results/multiQC/' + this.props.run +'.multiqc_report.html'
            // var QCreport_path = this.props.run +'.multiqc_report.html'
            var coverage = this.props.sample !== null ? <div>
                <SearchTable
                    data={this.props.cov ? this.props.cov[this.props.sample] : null}
                    table={'Summary'}
                    trigger={trigger}
                    callAPI={this.props.callAPI}
                />
            </div> :
                <div>
                    <h2>Please select a sample</h2>
                </div>

            return (
                <div>
                    <div>
                        <div>
                            Number of analysed samples: {nsamples}
                        </div>

                        {summary_table}
                    </div>
                    <br></br>
                    <h2>MultiQC reports</h2>
                    <Row>
                        <Col>
                            <Breadcrumb >
                                <Breadcrumb.Item active>
                                    <button onClick={this.handleDownloadFirst} style={{color: '#007bff'}} onMouseEnter={this.underlineOn} onMouseLeave={this.underlineOff}> First QC report </button>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <button onClick={this.handleDownloadSecond} style={{color: '#007bff'}} onMouseEnter={this.underlineOn} onMouseLeave={this.underlineOff}> Second QC report </button>
                                </Breadcrumb.Item>
                                {/*<div dangerouslySetInnerHTML={this.createMarkup(qc)} />*/}
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'start' }}>
                        <h2>Coverage</h2>
                    </div>
                    {coverage}
                </div >
            )
        } else {
            return (<h1>Please select a run</h1>)
        }
    }
}

const mapStateToProps = (state) => {
    return {
        sample: state.sample,
        projects: state.projects
    }
}
const mapDispatchToProps = {addAlert} // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 
export default connect(mapStateToProps, mapDispatchToProps)(Summary)