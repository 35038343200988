import { createStore, combineReducers } from 'redux';
import AlertReducer from './reducers/alerts';
import CommentReducer from "./reducers/comment";

//Action - list of actions we can do
//located in action.js

//Reducer - can modify state
const filterReducer = (state = [], action) => {

    /////global preset name exception !!!!!!!!!!!!
    switch (action.type) {
        case 'filterAction':
            var global = JSON.parse(JSON.stringify(state))
            //TODO: logout, reset, save
            if (Object.keys(action.payload)[0] === 'loaded_global') { return action.payload['loaded_global'] }
            if (state.length === 0) { return action.payload }
            if (action.payload[0] === 'logout') { return [] }
            if (Object.keys(action.payload)[0] === 'reset') {
                global[Object.keys(action.payload['reset'])] = action.payload['reset'][Object.keys(action.payload['reset'])]
                return global
            }
            if (action.payload[Object.keys(action.payload)] === undefined) { return state } //if new preset is empty, do nothing to avoid errors


            // if filter for current p/t exists, overwrite
            var preset = Object.keys(action.payload)
            var new_table = Object.keys(action.payload[preset])[0]
            var kind = Object.keys(action.payload[preset][new_table])[0]
            if (action.payload[preset][new_table][kind][0] !== undefined) {
                var new_id = action.payload[preset][new_table][kind][0].id
                if (global[preset] ? true : false) {
                    if (global[preset][new_table]) { // if table exists in preset
                        //var col_row = global[preset][new_table]
                        //var tab_ind = global[preset].map(x => Object.keys(x)[0] == new_table).findIndex(function isBigEnough(x) { return x == true })

                        if (kind === 'filters') { // if we update rows
                            var rows = global[preset][new_table].filters
                            if (rows ? rows.map(x => x.id).includes(new_id) : false) { // if row filter exists, overwrite
                                var which = global[preset][new_table][kind].map(x => x.id === new_id).findIndex(function fun(x) { return x === true })
                                global[preset][new_table][kind][which] = action.payload[preset][new_table][kind][0]
                            } else { //if not, add a new row filter
                                global[preset][new_table][kind] = [...global[preset][new_table][kind], action.payload[preset][new_table][kind][0]]
                            }
                        } else { // if we update columns
                            global[preset][new_table].columns = action.payload[preset][new_table].columns
                            if (!global[preset][new_table].filters) { // We put an empty matrix into filters to avoid stupid .map() errors later on
                                global[preset][new_table].filters = []
                            }
                        }

                    } else {
                        global[preset] = { ...global[preset], ...action.payload[preset] } // add table if not in preset
                        if (!global[preset][new_table].filters) {// We put an empty matrix into filters to avoid stupid .map() errors later on
                            global[preset][new_table].filters = []
                        }
                    }
                } else {
                    global[preset] = action.payload[preset]
                }
                return global
            } else {
                return state
            }
        default:
            return state
    }
}


const presetReducer = (state = null, action) => {
    switch (action.type) {
        case 'presetAction':
            return action.payload
        default:
            return state
    }
}

const presetListReducer = (state = null, action) => {
    switch (action.type) {
        case 'presetListAction':
            return action.payload
        default:
            return state
    }
}

const userReducer = (state = null, action) => {
    switch (action.type) {
        case 'userAction':
            return action.payload
        default:
            return state
    }
}

const projectReducer = (state = null, action) => {
    switch (action.type) {
        case 'projectAction':
            return action.payload
        default:
            return state
    }
}

const projectsReducer = (state = null, action) => {
    switch (action.type) {
        case 'projectsAction':
            return action.payload
        default:
            return state
    }
}

const serverProjectsReducer = (state = null, action) => {
    switch (action.type) {
        case 'serverProjectsAction':
            return action.payload
        default:
            return state
    }
}

const runReducer = (state = null, action) => {
    switch (action.type) {
        case 'runAction':
            return action.payload
        default:
            return state
    }
}

const runsReducer = (state = null, action) => {
    switch (action.type) {
        case 'runsAction':
            if (action.payload === undefined) {
                return null
            }
            return action.payload
        default:
            return state
    }
}

const sampleReducer = (state = null, action) => {
    switch (action.type) {
        case 'sampleAction':
            return action.payload
        default:
            return state
    }
}

const runSamplesReducer = (state = null, action) => {
    switch (action.type) {
        case 'runSamplesAction':
            return action.payload
        default:
            return state
    }
}


const userTablesReducer = (state = null, action) => {
    switch (action.type) {
        case 'userTablesAction':
            return action.payload
        default:
            return state
    }
}

const tableClickReducer = (state = null, action) => {
    switch (action.type) {
        case 'tableClickAction':
            return action.payload
        default:
            return state
    }
}

const segmentClickReducer = (state = null, action) => {
    switch (action.type) {
        case 'segmentClickAction':
            return action.payload
        default:
            return state
    }
}

const CNVGlobalReducer = (state = null, action) => {
    switch (action.type) {
        case 'CNVGlobalAction':
            return action.payload
        default:
            return state
    }
}

const tabIndexReducer = (state = null, action) => {
    switch (action.type) {
        case 'tabIndexAction':
            return action.payload
        default:
            return state
    }
}

const locusReducer = (state = null, action) => {
    switch (action.type) {
        case 'locusAction':
            return action.payload
        default:
            return state
    }
}

const backendReducer = (state = null, action) => {
    switch (action.type) {
        case 'backendAction':
            return action.payload
        default:
            return state
    }
}



//Combined reducer - must be there even if we have only 1 reducer!
const reducers = combineReducers({
    globalFilters: filterReducer,
    preset: presetReducer,
    presetList: presetListReducer,
    user: userReducer,
    project: projectReducer,
    projects: projectsReducer,
    serverProjects: serverProjectsReducer,
    run: runReducer,
    runs: runsReducer,
    sample: sampleReducer,
    runSamples: runSamplesReducer,
    userTables: userTablesReducer,
    alerts: AlertReducer,
    comment: CommentReducer,
    tableClick: tableClickReducer,
    CNVGlobal: CNVGlobalReducer,
    segmentClick: segmentClickReducer,
    tabIndex: tabIndexReducer,
    locus: locusReducer,
    backend: backendReducer
});

//Store - global state storage
let Store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) //redux devtools extension enabled

export default Store;
