import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap';

class SampleInfo extends React.Component {
    
    render() {
        var rearrangements_info = this.props.info === null | this.props.info === undefined ?
            <div></div> :
            <div className="sampleInfoDiv_grow" style={{ height: this.props.show ? "80px" : "0px" }}>
                <Table striped bordered size="sm">
                    <thead>
                        <tr>
                            <th>in FASTQs</th>
                            <th>usable</th>
                            <th>duplicated</th>
                            <th>mapped</th>
                            <th>on target</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.info[0]}</td>
                            <td>{this.props.info[4]}</td>
                            <td>{this.props.info[1]}</td>
                            <td>{this.props.info[2]}</td>
                            <td>{this.props.info[3]}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>

        return (
            <div>
                {rearrangements_info}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}
const mapDispatchToProps = {} // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 
export default connect(mapStateToProps, mapDispatchToProps)(SampleInfo)