import React from 'react'
// import igv from 'tmp_es6_igv';
import igv from 'igv'
import { connect } from 'react-redux'
import $ from 'jquery'
import Selection from '../Shared/Selection'
import { locusAction } from '../../actions/action'
import axios from 'axios'


class IGV extends React.Component {

    componentDidMount() {

        var localHeight = 500;
        if ($(window).height() && ($(window).height() - 290 > 500)) {
            localHeight = $(window).height() - 290;
        }
        localHeight = localHeight - 240;

        if (!this.props.user || !this.props.run || !this.props.sample) {
            return null;
        }
        const igvContainer = document.getElementById('igv-div');
        const igvOptions =
        {
            genome: 'hg38',
            locus: this.props.locus ? (this.props.locus.partner ? this.props.locus.locus + " " + this.props.locus.partner : this.props.locus.locus) : "chr17:7,574,720-7,578,868",
            type: "alignment",
            showCursorTrackingGuide: true,
            showCenterGuide: true,
            tracks: [
                {
                    name: this.props.sample,
                    format: "bam",
                    height: localHeight,
                    url: window.location.protocol + '//' + (window.location.hostname !== "lynx.ceitec.muni.cz" ? (window.location.hostname + "/api") : window.location.hostname) + '/api/IGV/' + this.props.user + '/' + this.props.run + '/' + this.props.sample + '/' + (this.props.locus ? this.props.locus.source : "SNVCNV"),
                    indexURL: window.location.protocol + '//' + (window.location.hostname !== "lynx.ceitec.muni.cz" ? (window.location.hostname + "/api") : window.location.hostname) + '/api/IGV/index/' + this.props.user + '/' + this.props.run + '/' + this.props.sample + '/' + (this.props.locus ? this.props.locus.source : "SNVCNV"),
                    colorBy: "strand",
                    headers: {authorization: axios.defaults.headers.authorization}
                }
            ]
        }
        return igv.createBrowser(igvContainer, igvOptions)
            .then(function (browser) {
                igv.browser = browser;
            });
    }


    componentDidUpdate() {

        var localHeight = 500;
        if ($(window).height() && ($(window).height() - 290 > 500)) {
            localHeight = $(window).height() - 290;
        }
        localHeight = localHeight - 240;

        if (!this.props.user || !this.props.run || !this.props.sample || !igv.browser) {
            return null;
        }

        const igvOptions =
        {
            name: this.props.sample,
            format: "bam",
            height: localHeight,
            url: window.location.protocol + '//' + (window.location.hostname !== "lynx.ceitec.muni.cz" ? (window.location.hostname + "/api") : window.location.hostname) + '/IGV/' + this.props.user + '/' + this.props.run + '/' + this.props.sample + '/' + (this.props.locus ? this.props.locus.source : "SNVCNV"),
            indexURL: window.location.protocol + '//' + (window.location.hostname !== "lynx.ceitec.muni.cz" ? (window.location.hostname + "/api") : window.location.hostname) + '/IGV/index/' + this.props.user + '/' + this.props.run + '/' + this.props.sample + '/' + (this.props.locus ? this.props.locus.source : "SNVCNV"),
            colorBy: "strand",
            headers: {authorization: axios.defaults.headers.authorization}
        }

        let sampleNames = this.props.samples.map(x => x.value)
        let tracks = igv.browser.findTracks();

        for (let i = 0; i < tracks.length; i++) {
            console.log(tracks[i].name)
            if (sampleNames.includes(tracks[i].name)) {
                igv.browser.removeTrack(tracks[i]);
            }
        }
        return igv.browser.loadTrack(igvOptions);
    }

    IGVHandler = (e) => {
        this.props.locusAction({ source: e })
    }

    render() {
        var tableHeight = 500;
        if ($(window).height() && ($(window).height() - 290 > 500)) {
            tableHeight = $(window).height() - 290;
        }

        const igvStyle = {
            paddingTop: '10px',
            paddingBottom: '10px',
            margin: '8px',
            border: '1px solid lightgray',
            height: tableHeight + 'px'
        }

        var label = this.props.locus ? this.props.locus.source : "SNVCNV"

        switch (label) {
            case "SNVCNV":
              label = "SNV/CNV pipeline"
              break;
            case "RearTrans":
              label = "Rear/Trans pipeline"
              break
            case "Realign":
               label = "Variant calling realignment"
              break
          }
          //label = label == 'RearTrans' ? 'Rear/Trans pipeline' : 'SNV/CNV pipeline'

        return (
            <div>
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    Currently using <div style={{width: '200px', margin: '10px'}}> <Selection
                        id='IGVBam'
                        projectFun={this.IGVHandler}
                        choices={[{ value: 'SNVCNV', label: 'SNV/CNV pipeline' },
                        { value: 'RearTrans', label: 'Rear/Trans pipeline' },
                        { value: 'Realign', label: 'Variant calling realignment' }]}
                        value={{
                            value: this.props.locus ? this.props.locus.source : "SNVCNV",
                            label: label
                        }} /></div> bam for display.
                </div>
                <div id="igv-div" style={igvStyle}></div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locus: state.locus
    }
}
const mapDispatchToProps = { locusAction }
export default connect(mapStateToProps, mapDispatchToProps)(IGV)