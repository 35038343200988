import React from 'react'
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"
import { connect } from 'react-redux'
import { runSamplesAction, projectsAction } from '../../actions/action'
import bin from './../img/bin.png'
import axios from 'axios'
import { Modal, Button } from 'react-bootstrap'
import { addAlert } from '../../actions/alerts';


class SamplesTab extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showDel: false
        }
    }

    refresh = () => {
        this.forceUpdate()
        this.props.addAlert({ message: `table refreshed` })
        axios.post('file/list', { user: this.props.user })
                        .then(res2 => {
                            // this.props.projectsAction(res2.data.map(x => [{ value: Object.keys(x)[0], label: Object.keys(x)[0], samples: Object.values(x)[0] }][0]))
                            this.props.projectsAction(res2.data.map(x => [{
                                value: Object.keys(x)[0],
                                label: Object.keys(x)[0],
                                samples: Object.values(x)[0].map(y => Object.keys(y)[0]),
                                sizes: Object.values(x)[0].map((y, i) => ({[Object.values(x)[0].map(y => Object.keys(y)[0])[i]]: Object.values(y)[0]}))
                            }][0]));
                        })
    }

    handleClick = e => {
        this.props.runSamplesAction(e)
    }

    deleteSample = (row) => {
        this.delSample = row.row._original.sample
        this.setState({ showDel: true })
    }

    hideModal = () => {
        this.setState({ showDel: false })
    }

    sampleDelete = () => {
        axios.post('file/delete', { user: this.props.user, project: this.props.project, sample: this.delSample })
            .then(res => {
                if (res.data === true) {
                    this.props.addAlert({ message: 'deletion successful' });

                    axios.post('file/list', { user: this.props.user })
                        .then(res2 => {
                            // this.props.projectsAction(res2.data.map(x => [{ value: Object.keys(x)[0], label: Object.keys(x)[0], samples: Object.values(x)[0] }][0]))
                            this.props.projectsAction(res2.data.map(x => [{
                                value: Object.keys(x)[0],
                                label: Object.keys(x)[0],
                                samples: Object.values(x)[0].map(y => Object.keys(y)[0]),
                                sizes: Object.values(x)[0].map((y, i) => ({[Object.values(x)[0].map(y => Object.keys(y)[0])[i]]: Object.values(y)[0]}))
                            }][0]));
                        })
                    this.setState({ showDel: false })
                } else {
                    this.props.addAlert({ message: 'Sample deletion error: ' + res.data });
                }
            })
    }


//     <div class='containerrr'>
//     <div class='barrr'>
//     </div>
//     </div>

    render() {
        var unique_sample_list = this.props.samples ? [...new Set(this.props.samples.map(x => x.replace(/_R1.fastq|_R2.fastq|.UMI.fastq/, '').replace('.gz', '')))] : null;
        if (unique_sample_list && unique_sample_list.includes("originalSizes.list")) {
            unique_sample_list = unique_sample_list ? unique_sample_list.filter(x => x !== "originalSizes.list") : null;
        }
        
        var samples = unique_sample_list ? unique_sample_list.map(x => [{
            sample: x,
            R1: this.props.samples.includes(x.concat('_R1.fastq.gz')) ?
                Object.values(this.props.projects.filter(y => y.value === this.props.project)[0].sizes.filter(y => Object.keys(y)[0] === x.concat('_R1.fastq.gz'))[0])[0] :
                '-',
            R2: this.props.samples.includes(x.concat('_R2.fastq.gz')) ?
                Object.values(this.props.projects.filter(y => y.value === this.props.project)[0].sizes.filter(y => Object.keys(y)[0] === x.concat('_R2.fastq.gz'))[0])[0] :
                '-',
            UMI: this.props.samples.includes(x.concat('.UMI.fastq')) ?
                Object.values(this.props.projects.filter(y => y.value === this.props.project)[0].sizes.filter(y => Object.keys(y)[0] === x.concat('.UMI.fastq'))[0])[0] :
                '-',
            Delete: this.deleteSample
        }][0]) : null
        
        //var samples = this.props.samples ? this.props.samples.map(x => [{ sample: x }][0]) : null
        //var selected = this.props.runSamples ? this.props.runSamples : []

        var originalSizes = null;
        if ( this.props.project && this.props.projects) {
            originalSizes = this.props.projects.map(y => y.value).includes(this.props.project) && this.props.projects.filter(y => y.value === this.props.project)[0].sizes.map(x => Object.keys(x)[0]).includes("originalSizes.list") ? Object.values(this.props.projects.filter(y => y.value === this.props.project)[0].sizes.filter(y => Object.keys(y)[0] === "originalSizes.list")[0])[0] : null;
            originalSizes = originalSizes ? originalSizes.split('\n').map(x => x.split('\t')) : null;
        }
        

        const cols = [{
            Header: () => (
                <div style={{ textAlign: "left" }}>Sample</div>
            ), accessor: "sample"
        },
        {
            Header: "R1", accessor: 'R1', Cell: row => {
                let samplesTabState = "samplesTabPresent";
                let rowPrint = row.original.sample + "_R1.fastq.gz (" + (row.value / (1024 * 1024)).toFixed(2) + " MB)";
                if (isNaN(row.value)) {
                    samplesTabState = "samplesTabMissing";
                    rowPrint = "missing file (0.00 MB)";
                } else if (originalSizes && row.value !== Number(originalSizes.filter(x => x[0] === row.original.sample + "_R1.fastq.gz")[0][1])) {
                    samplesTabState = "samplesTabInProgress";
                    rowPrint = "copying " + row.original.sample + "_R1.fastq.gz (" + (row.value / (1024 * 1024)).toFixed(2) + " MB)";
                }
                return( <div class={samplesTabState} style={{ textAlign: "center" }}> {rowPrint} </div> )
            }
        },
        {
            Header: "R2", accessor: 'R2', Cell: row => {
                let samplesTabState = "samplesTabPresent";
                let rowPrint = row.original.sample + "_R2.fastq.gz (" + (row.value / (1024 * 1024)).toFixed(2) + " MB)";
                if (isNaN(row.value)) {
                    samplesTabState = "samplesTabMissing";
                    rowPrint = "missing file (0.00 MB)";
                } else if (originalSizes && row.value !== Number(originalSizes.filter(x => x[0] === row.original.sample + "_R2.fastq.gz")[0][1])) {
                    samplesTabState = "samplesTabInProgress";
                    rowPrint = "copying " + row.original.sample + "_R2.fastq.gz (" + (row.value / (1024 * 1024)).toFixed(2) + " MB)";
                }
                return( <div class={samplesTabState} style={{ textAlign: "center" }}> {rowPrint} </div> )
            }
        },
        {
            Header: "UMI", accessor: 'UMI', Cell: row => {
                let samplesTabState = "samplesTabPresent";
                let rowPrint = row.original.sample + ".UMI.fastq (" + (row.value / (1024 * 1024)).toFixed(2) + " MB)";
                if (isNaN(row.value)) {
                    samplesTabState = "samplesTabMissing";
                    rowPrint = "missing file (0.00 MB)";
                } else if (originalSizes && row.value !== Number(originalSizes.filter(x => x[0] === row.original.sample + ".UMI.fastq")[0][1])) {
                    samplesTabState = "samplesTabInProgress";
                    rowPrint = "copying " + row.original.sample + ".UMI.fastq (" + (row.value / (1024 * 1024)).toFixed(2) + " MB)";
                }
                return( <div class={samplesTabState} style={{ textAlign: "center" }}> {rowPrint} </div> )
            }
        },
        {
            Header: "Delete", accessor: 'Delete', Cell: row => (
                <div style={{ textAlign: "center" }}>
                    <img src={bin} style={{ cursor: 'pointer' }} alt="bin" width="23" height="23" onClick={() => this.deleteSample(row)} ></img>
                </div>
            ), width: 80
        }
        ]



        return (<div>
            {this.props.samples ?
            <div>
                <Button variant="secondary" size="sm" style={{ "marginBottom": "8px" }} onClick={this.refresh} data-tip={"Refresh progress table and result list if they're not visible."} >Refresh</Button>
                <ReactTable
                    style={{ 'maxHeight': '400px' }}
                    pageSize={samples.length}
                    columns={cols}
                    data={samples}
                    showPagination={false}
                /*getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                        if (rowInfo.original.R1 === '+' & rowInfo.original.R2 === '+' & rowInfo.original.UMI === '+') {
                            return {
                                onClick: (e) => {
                                    if (selected.includes(rowInfo.index)) {
                                        var arr = selected
                                        var ind = arr.findIndex(function isBigEnough(element) {
                                            return element === rowInfo.index
                                        })
                                        arr.splice(ind, 1)
                                        this.setState({
                                            selected: arr
                                        })
                                        this.props.runSamplesAction(arr)
                                    } else {
                                        this.setState({
                                            selected: selected.concat(rowInfo.index)
                                        })
                                        this.props.runSamplesAction(selected.concat(rowInfo.index))
                                    }
                                },
                                style: {
                                    background: selected.includes(rowInfo.index) ? '#00afec' : 'white',
                                    color: selected.includes(rowInfo.index) ? 'white' : 'black'
                                }
                            }
                        } else {
                            return {}
                        }
                    }
                }}*/
                />
                </div> : <div>
                    <h2>Please log in and select a project to see uploaded samples</h2>
                </div>}

            <Modal show={this.state.showDel} onHide={this.hideModal} size="lg" centered>
                <Modal.Header closeButton >
                    <Modal.Title>Do you want to remove the sample {this.delSample} ?</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="danger" onClick={this.sampleDelete}>Yes</Button>
                    <Button variant="secondary" onClick={this.hideModal}>No</Button>
                </Modal.Footer>
            </Modal>
        </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        project: state.project,
        projects: state.projects,
        runSamples: state.runSamples
    }
}
const mapDispatchToProps = { runSamplesAction, addAlert, projectsAction } // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 
export default connect(mapStateToProps, mapDispatchToProps)(SamplesTab)