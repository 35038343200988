export const filterAction = (flt) => {
    return {
        type: 'filterAction',
        payload: flt
    }
}

export const presetAction = (pres) => {
    return {
        type: 'presetAction',
        payload: pres
    }
}


export const presetListAction = (pres) => {
    return {
        type: 'presetListAction',
        payload: pres
    }
}

export const userAction = (usr) => {
    return {
        type: 'userAction',
        payload: usr
    }
}

export const runSamplesAction = (x) => {
    return {
        type: 'runSamplesAction',
        payload: x
    }
}

// unfortunatelly, for each select dropdown we need a separate action

export const projectAction = (x) => {
    return {
        type: 'projectAction',
        payload: x
    }
}

export const projectsAction = (x) => {
    return {
        type: 'projectsAction',
        payload: x
    }
}

export const serverProjectsAction = (x) => {
    return {
        type: 'serverProjectsAction',
        payload: x
    }
}

export const runAction = (x) => {
    return {
        type: 'runAction',
        payload: x
    }
}

export const runsAction = (x) => {
    return {
        type: 'runsAction',
        payload: x
    }
}

export const sampleAction = (x) => {
    return {
        type: 'sampleAction',
        payload: x
    }
}

export const userTablesAction = (x) => {
    return {
        type: 'userTablesAction',
        payload: x
    }
}
export const tableClickAction = (x) => {
    return {
        type: 'tableClickAction',
        payload: x
    }
}

export const segmentClickAction = (x) => {
    return {
        type: 'segmentClickAction',
        payload: x
    }
}

export const CNVGlobalAction = (x) => {
    return {
        type: 'CNVGlobalAction',
        payload: x
    }
}

export const tabIndexAction = (x) => {
    return {
        type: 'tabIndexAction',
        payload: x
    }
}

export const locusAction = (x) => {
    return {
        type: 'locusAction',
        payload: x
    }
}

export const backendAction = (x) => {
    return {
        type: 'backendAction',
        payload: x
    }
}