import React from 'react';


import axios from 'axios';
import { connect } from 'react-redux';
import {
    userAction,
    filterAction,
    presetListAction,
    runAction,
    runsAction,
    sampleAction,
    userTablesAction,
    projectsAction,
    serverProjectsAction,
    projectAction
} from '../actions/action';
import {
    addAlert
} from '../actions/alerts';

import Analysis from './Analysis/Analysis';
import Results from './Results/Results';
import AlertMsg from './Shared/Alerts';

import logo from './img/bobcat_color.png';
import contact from './img/contact.png';
import manual from './img/manual.png';
import './style/App.css';
import 'react-tabs/style/react-tabs.css';
import { Container, Modal, Row, Col, Tab, Tabs } from 'react-bootstrap';

class App extends React.Component {

    constructor() {
        super()
        this.state = {
            user: null,
            projects: null,
            runs: null,
            wrong_pass: null,
            login: false,
            modal: false
        }
    }

    // updauser specific global data
    loginHandler = () => {
        axios.defaults.baseURL = "https://lynx.ceitec.muni.cz/api"
        axios.post(`login`, { login: this.login.value, pass: this.pass.value })
            .then(res => {
                if (res.data) {
                    axios.defaults.headers.authorization = res.data.token
                    let user = res.data.user
                    // sessionStorage.setItem("token", res.data.token);
                    let userdata = axios.post(`userData`, { user: user });
                    let files = axios.post('file/list');
                    let filesResults = axios.post('file/listResults', { user: user });
                    let serverProjects = axios.post('file/listServerProjects', {});


                    Promise.resolve(files).then(files => { //we need to execute this first so that we properly handle missing storage/user folders
                        //handle files
                        // this.props.projectsAction(files.data.map(x => [{ value: Object.keys(x)[0], label: Object.keys(x)[0], samples: Object.values(x)[0] }][0]));
                        this.props.projectsAction(files.data.map(x => [{
                            value: Object.keys(x)[0],
                            label: Object.keys(x)[0],
                            samples: Object.values(x)[0].map(y => Object.keys(y)[0]),
                            sizes: Object.values(x)[0].map((y, i) => ({[Object.values(x)[0].map(y => Object.keys(y)[0])[i]]: Object.values(y)[0]}))
                        }][0]));

                        Promise.all([userdata, filesResults, serverProjects]).then(values => {
                            //handle userdata
                            if (values[0].data.presets !== undefined) {
                                this.props.presetListAction(['No preset', ...Object.keys(values[0].data.presets)]); // looking for unique values
                                this.props.filterAction({ 'loaded_global': { ...{ 'No preset': [] }, ...values[0].data.presets } });
                            }
                            this.props.userAction(user);

                            //handle results
                            this.props.runsAction(values[1].data);

                            //handle server projects
                            this.props.serverProjectsAction(values[2].data.map(x => [{ value: x, label: x }][0]));
                        })
                    }).catch(e => {
                        console.log("promises failed");
                        this.props.addAlert({ message: `Unable to log in: Unable to retrieve user data. ${e.message}` });
                    });
                } else {
                    this.props.addAlert({ message: "Unable to log in: Wrong username or password." });
                }
                this.setState({ login: true });
            }).catch(e => {
                this.props.addAlert({ message: `Unable to login: ${e.message}` });
            });
    }

    loginHandlerKeypress = (event) => {
        if (event.charCode === 13 || event.keyCode === 13 || event.key === "Enter") {
            this.loginHandler();
        }
    }

    // update global data on logout
    logoutHandler = () => {
        delete axios.defaults.headers.authorization
        this.props.userAction(null)
        this.setState({ projects: null })
        this.setState({ run: null })
        this.setState({ runs: null })
        this.setState({ login: false })

        this.props.presetListAction([])
        this.props.filterAction('logout')
        this.props.runAction(null)
        this.props.runsAction(null)
        this.props.sampleAction(null)
        this.props.serverProjectsAction(null)
        this.props.projectsAction(null)
        this.props.projectAction(null)

    }

    showModal = () => {
        this.setState({ modal: true })
    }
    hideModal = () => {
        this.setState({ modal: false })
    }

    render() {
        var modal = <Modal show={this.state.modal} onHide={this.hideModal} size="lg" centered>
            <Modal.Header closeButton >
                <Modal.Title>Contact and contributors</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        Email:
                        </Col>
                    <Col>
                        <a href="mailto: toollynx@gmail.com">toollynx@gmail.com</a>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        Acknowledgements:
                        Supported by TACR ZETA TJ02000133, MZCR-AZV NV19-03-00091 and MH-CZ RVO 65269705. Computational resources are supplied by the project "e-Infrastruktura CZ" (e-INFRA LM2018140) provided within the program Projects of Large Research, Development and Innovations Infrastructures.
                    </Col>
                </Row>

            </Modal.Body>
        </Modal>

        var logos = <div className="LynxNameWrap">
            <img src={logo} alt="LYNX logo" className="LynxLogo" />
            <span className="LynxName">LYNX</span>
            {modal}
        </div>

        var UserLogin = this.props.user
            ? (
                <div className="AppHeader" style={{ right: '20px', position: 'absolute' }}>

                    <div className="LoggedUserWrap">
                        <span className="LoggedUser"> {this.props.user} </span>
                    &nbsp;&nbsp;
                    {/* <button className="loggingBtn" onClick={this.logoutHandler}>Log out</button> */}
                        <div className="container-login100-form-btn">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button className="login100-form-btn" onClick={this.logoutHandler}>
                                    Log out
                              </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
            : (
                <div className="AppHeader" style={{ right: '20px', position: 'absolute' }}>

                    <div className="UserLoginWrap">
                        <div>
                            <div className="wrap-input100">
                                <input className="input100" type="text" id='login' ref={(c) => this.login = c} name='login' placeholder="user name" onKeyPress={this.loginHandlerKeypress} />
                                <span className="focus-input100"></span>
                            </div>
                            <div className="wrap-input100">
                                <input className="input100" type="password" id='pass' ref={(c) => this.pass = c} placeholder="password" onKeyPress={this.loginHandlerKeypress} />
                                <span className="focus-input100"></span>
                            </div>
                        </div>
                        {/* <button onClick={this.loginHandler}>Log in</button> */}
                        <div className="container-login100-form-btn">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button id='loginBTN' className="login100-form-btn" onClick={this.loginHandler}>
                                    Log in
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            )


        const TabsDisplay = (
            <Tabs>
                <Tab eventKey="home" title={logos}>
                    <br />
                    <h5 style={{ marginTop: '15px' }}>Version 2024-14-08</h5>
                    <br />
                    The LYNX (Lymphoid NGS) analytical software is a tailor-made bioinformatic tool for a new custom-designed capture-based NGS panel. With this tool, a user is able to analyze molecular markers such as gene mutations, copy number variations, antigen receptor rearrangements and translocations in the most common lymphoid malignancies.
                    <br />
                    <br />
                    {/* The user manual can be found here:<a href='https://docs.google.com/document/d/1sIgVCijVGui2P4GSB7qSxiJ7qZlFlqMeIy4bvRtxBHA/edit#' rel="noopener noreferrer" target="_blank"><img src={manual} alt='See the manual' style={{ width: '40px', height: '40px', margin: '5px', cursor: 'pointer' }} /></a> */}
                    {/* The user manual can be found here:<a href='https://drive.google.com/file/d/12Bz-4TRsXQMLZt1Oe1eJgVPdbzNgrdn3/view?usp=sharing' rel="noopener noreferrer" target="_blank"><img src={manual} alt='See the manual' style={{ width: '40px', height: '40px', margin: '5px', cursor: 'pointer' }} /></a> */}
                    The user manual can be found here:<a href='manual/LYNX_manual.pdf' rel="noopener noreferrer" target="_blank"><img src={manual} alt='See the manual' style={{ width: '40px', height: '40px', margin: '5px', cursor: 'pointer' }} /></a>

                    
                    <br />
                    Contact and contributors: <img src={contact} onClick={this.showModal} alt='See contact details' style={{ width: '40px', height: '40px', margin: '5px', cursor: 'pointer' }} />
                </Tab>
                <Tab eventKey="Analysis" title="Analysis" tabClassName='navbar-tab'>
                    <br />
                    <Analysis />
                </Tab>
                <Tab eventKey="Results" title="Results" tabClassName='navbar-tab'>
                    <br />
                    <Results presets={this.props.presets} login={this.state.login} />
                </Tab>

            </Tabs>)

        return (
            <div className="App">
                <Container fluid={true}>
                    <AlertMsg></AlertMsg>
                    {UserLogin}
                    {TabsDisplay}

                </Container>
            </div>
        )
    }
}

//Passing global state as props (redux)
const mapStateToProps = (state) => {
    return {
        user: state.user ? state.user : null,
        presetList: state.presetList,


    }
}
const mapDispatchToProps = {
    userAction, filterAction, presetListAction, runsAction,
    runAction, sampleAction, userTablesAction, serverProjectsAction, projectsAction, projectAction, addAlert
} // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 
export default connect(mapStateToProps, mapDispatchToProps)(App)
