// lenghts of individual chromosomes hg38 reference
export const CHR_LEN = {
  1:  248956422,
  2:  242193529,
  3:  198295559,
  4:  190214555,
  5:  181538259,
  6:  170805979,
  7:  159345973,
  8:  145138636,
  9:  138394717,
  10: 133797422,
  11: 135086622,
  12: 133275309,
  13: 114364328,
  14: 107043718,
  15: 101991189,
  16:  90338345,
  17:  83257441,
  18:  80373285,
  19:  58617616,
  20:  64444167,
  21:  46709983,
  22:  50818468,
  'X': 156040895,
  'Y': 57227415
};
export const CUMULATIVE_LEN_START = {
  1:           0,
  2:   248956422,
  3:   491149951,
  4:   689445510,
  5:   879660065,
  6:  1061198324,
  7:  1232004303,
  8:  1391350276,
  9:  1536488912,
  10: 1674883629,
  11: 1808681051,
  12: 1943767673,
  13: 2077042982,
  14: 2191407310,
  15: 2298451028,
  16: 2400442217,
  17: 2490780562,
  18: 2574038003,
  19: 2654411288,
  20: 2713028904,
  21: 2777473071,
  22: 2824183054,
  'X': 2875001522,
  'Y': 3031042417 
};

export const CUMULATIVE_LEN_END = {
  1:   248956422,
  2:   491149951,
  3:   689445510,
  4:   879660065,
  5:  1061198324,
  6:  1232004303,
  7:  1391350276,
  8:  1536488912,
  9:  1674883629,
  10: 1808681051,
  11: 1943767673,
  12: 2077042982,
  13: 2191407310,
  14: 2298451028,
  15: 2400442217,
  16: 2490780562,
  17: 2574038003,
  18: 2654411288,
  19: 2713028904,
  20: 2777473071,
  21: 2824183054,
  22: 2875001522,
  'X': 3031042417,
  'Y': 3088269832
};

export const BAND_COLORS =  {
  "gpos25" :"#f0f0f0",
  "gpos50" :"#bdbdbd",
  "gpos75" :"#636363",
  "gpos100":"#010101",
  "gneg"   :"#ffffff",
  "gvar"   :"#99d594",
  "acen"   :"#fc8d59"
};