import React from 'react'
import SearchTable from './SearchTable'
import { connect } from 'react-redux'

class Translocations extends React.Component {

    render() {
        return (
            this.props.data === null ?
                <h1> select a sample </h1> :
                !this.props.info ?
                    <h1> Translocations analysis was not performed for this sample </h1> :
                    !this.props.data ?
                        <h1> Translocations were not detected for this sample </h1> :
                        <div>
                            <SearchTable
                                data={this.props.data}
                                run={this.props.run}
                                sample={this.props.sample}
                                user={this.props.user}
                                table={"Trans"}
                                info={this.props.info}
                            />
                        </div>
        )
    }
}

//Passing global state as props (redux)
const mapStateToProps = (state) => {
    return {
        comment: state.comment,
        preset: state.preset,
        run: state.run,
        sample: state.sample,
        user: state.user,
        globalFilters: state.globalFilters,
        columns: state.columns
    }
}
const mapDispatchToProps = {  } // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 
export default connect(mapStateToProps, mapDispatchToProps)(Translocations)