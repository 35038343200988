import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { addAlert } from '../../actions/alerts';
import { updateCommentLocal, updateCommentRemote } from '../../actions/comment';
import { Col, Row, Button } from 'react-bootstrap'

class SampleNotes extends React.Component {
    constructor(props) {
        super(props)
        this.multiselectRef = React.createRef()
    }

    tabToComment = x => {
        return(x === "CNV" ? "CNVcomment" : "SNVcomment")
    }
    
    handleCommentChange = x => {
        if (x.target.value !== this.props.commentLocal[this.tabToComment(this.props.tab)]) {
            let commentsUpdate = {};
            if (this.props.tab === "CNV") {
                commentsUpdate = Object.assign({}, this.props.commentLocal, {
                    CNVcomment: x.target.value,
                });
            } else {
                commentsUpdate = Object.assign({}, this.props.commentLocal, {
                    SNVcomment: x.target.value,
                });
            }
            
            this.props.updateCommentLocal(commentsUpdate);
        }
    }

    noteSave = () => {
        axios.post(`comment`, {
            note: this.props.commentLocal[this.tabToComment(this.props.tab)] === undefined ? "" : this.props.commentLocal[this.tabToComment(this.props.tab)],
            run: this.props.run,
            sample: this.props.sample,
            user: this.props.user,
            comment: this.tabToComment(this.props.tab)
        }).then(
            res => {
                this.props.addAlert({ message: res.data });
                this.props.updateCommentRemote(this.props.commentLocal);
            })
    }

    render() {
        return (
            <div className="sampleNotesDiv_grow" style={{ height: this.props.show ? "120px" : "0px" }}>
                <Row >
                    <Col xs={10}>
                        <textarea
                            className="sampleNotesArea"
                            value={this.props.commentLocal[this.tabToComment(this.props.tab)] !== undefined ? this.props.commentLocal[this.tabToComment(this.props.tab)] : ""}
                            placeholder={this.props.waitingForComment}
                            type="text"
                            onChange={this.handleCommentChange}
                        />
                    </Col>
                    <Col xs={2}>
                        <Button
                            variant="secondary"
                            size="sm"
                            className="fillAvailableBtns"
                            onClick={this.noteSave}
                            disabled={this.props.commentLocal[this.tabToComment(this.props.tab)] === this.props.commentRemote[this.tabToComment(this.props.tab)] ? true : false}
                        >Save current note</Button>
                    </Col>
                </Row>
            </div >
        )
    }
}

//Passing global state as props (redux)
const mapStateToProps = (state) => {
    return {
        run: state.run,
        sample: state.sample,
        user: state.user,
        commentLocal: state.comment.commentLocal,
        commentRemote: state.comment.commentRemote
    }
}
const mapDispatchToProps = { addAlert, updateCommentLocal, updateCommentRemote } // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 
export default connect(mapStateToProps, mapDispatchToProps)(SampleNotes)