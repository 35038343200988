import React from 'react';
import { Component } from 'react';
import { Plotting } from './plotting';
import { connect } from 'react-redux'
import { segmentClickAction, tableClickAction, CNVGlobalAction } from '../../actions/action'

class LymphoCNV extends Component {
    constructor(props) {
        super(props)
        this.plot = {};
        this.state = {
            loading: true,
            sample: "",
            run: "",
            tableClick: null,
            diploidy: null,
            seg: true
        }
    }

    componentDidMount() {
        //creating the plot object is pretty heavy
        //the setTimout function should make the call asynchronous
        //so it doesn't block the rest of the react app
        setTimeout(() => {
            this.plot = new Plotting([...this.props.data, this.segmentClick, this.chrClick, this.props.CNVGlobal, this.props.diploidy, this.props.seg]);
            this.setState({ loading: false, sample: this.props.sample, run: this.props.run, diploidy: this.props.diploidy, seg: this.props.seg });
        }, 1)
    }

    segmentClick = (e) => {
        this.props.segmentClickAction(e)
    }

    chrClick = (e) => {
        if (Object.keys(e)[0] === 'chromosome') {
            this.props.CNVGlobalAction({ row: e })
        } else {
            this.props.CNVGlobalAction({ row: { chromosome: this.props.CNVGlobal.row.chromosome, start: e.pos[0].toString(), end: e.pos[1].toString() } })
        }
    }

    componentWillUnmount() {
        this.props.tableClickAction(null)
    }

    brushSet = (e) => {
        /*
        if (e !== undefined) {
            if (JSON.stringify(this.props.tableClick) !== JSON.stringify({ row: { chromosome: e, start: e.start, end: e.end } })) {
                this.props.tableClickAction({ row: { chromosome: e, start: e.start, end: e.end } })
            }
        }*/
    }

    componentDidUpdate() {
        if (this.state.sample !== this.props.sample || this.state.run !== this.props.run || this.state.tableClick !== this.props.tableClick || this.state.diploidy !== this.props.diploidy || this.state.seg !== this.props.seg) {
            if (this.state.tableClick !== this.props.tableClick) {
                this.plot.update(this.props.tableClick);
                this.props.CNVGlobalAction({ row: this.props.tableClick.row })
                this.setState({ loading: false });
            } else if (Object.keys(this.plot).length !== 0) {
                this.setState({ loading: true })
                this.plot.destroy();
                setTimeout(() => {
                    this.plot = new Plotting([...this.props.data, this.segmentClick, this.chrClick, null, this.props.diploidy, this.props.seg]);
                    this.setState({ loading: false });
                }, 1);
            }
            this.setState({ sample: this.props.sample, run: this.props.run, tableClick: this.props.tableClick, diploidy: this.props.diploidy, seg: this.props.seg });
        }
    }

    render() {
        return (
            <div>
                {this.state.loading ? <h4>Loading CNV...</h4> : ""}
                <div className="view-port">
                </div>
            </div>
        );
    }

}
const mapStateToProps = (state) => {
    return {
        tableClick: state.tableClick,
        CNVGlobal: state.CNVGlobal
    }
}
const mapDispatchToProps = { segmentClickAction, tableClickAction, CNVGlobalAction }
export default connect(mapStateToProps, mapDispatchToProps)(LymphoCNV)