import React from 'react'
import SearchTable from './SearchTable'
import Plotting from './LymphoCNV'
import { connect } from 'react-redux'
import { filterAction } from '../../actions/action'
import { Row, Col, Button } from 'react-bootstrap'
import { addAlert } from '../../actions/alerts';
import { updateCommentLocal, updateCommentRemote } from '../../actions/comment';

class CNV extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            ploidy: 0,
            seg: true
        }
        this.plotRef = React.createRef()
    }

    componentDidMount() {
        this.load();
    }

    changePloidy = (x) => {
        this.setState({ ploidy: x })
    }

    changeSeg = (x) => {
        this.setState({ seg: !this.state.seg })
    }

    load() {
        this.setState({ loaded: true });
    }

    render() {
        if (!this.state.loaded) {
            return (<div>Loading</div>)
        } else {
            return (
                this.props.data === null | this.props.data === undefined ?
                    <h1> select a sample </h1> :
                    !this.props.data[2] ?
                        <h1> CNV data not available for this sample </h1> :
                        <div>
                            <Button
                                style={{ backgroundColor: this.state.seg ? 'gray' : 'orange', borderColor: '#6c757d'}}
                                onClick={this.changeSeg}
                            >
                                {this.state.seg ? 'Hide segments' : 'Show Segments'}
                            </Button>
                            <div style={{ width: '100%' }} ref={this.plotRef}>
                                {/* {this.props.data != null && this.props.data[2] ?
                                    <Plotting data={this.props.data} sample={this.props.sample} run={this.props.run} diploidy={this.state.ploidy} /> :
                                    <div></div>} */}
                                <Plotting data={this.props.data} sample={this.props.sample} run={this.props.run} diploidy={this.state.ploidy} seg={this.state.seg} />
                            </div>

                            <br></br>
                            <Row >
                                <Col>
                                    <div >
                                        <SearchTable
                                            data={this.props.tab}
                                            table={'CNV'}
                                            trigger={this.props.segmentClick}
                                            scroll={this.plotRef}
                                            changePloidy={this.changePloidy}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div >
            )
        }
    }
}

//Passing global state as props (redux)
const mapStateToProps = (state) => {
    return {
        globalFilters: state.globalFilters,
        run: state.run,
        sample: state.sample,
        user: state.user,
        preset: state.preset,
        commentLocal: state.comment.commentLocal,
        commentRemote: state.comment.commentRemote
    }
}
const mapDispatchToProps = { filterAction, addAlert, updateCommentLocal, updateCommentRemote } // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 
export default connect(mapStateToProps, mapDispatchToProps)(CNV)
