import React from 'react'
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"
import { connect } from 'react-redux'
import { filterAction, presetAction, tableClickAction, segmentClickAction, tabIndexAction, locusAction, CNVGlobalAction, backendAction } from '../../actions/action'
import './CSS.css' //fix to crazy virtualized ReactTable scrollbar behavior
// import ExportReactXLS from './ExportReactXLS' // table download
import axios from 'axios'
import { addAlert } from '../../actions/alerts'
import IGV from '../img/igv.png'
import ReactTooltip from 'react-tooltip'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import $ from 'jquery'
import SampleNotes from './SampleNotes'
import SampleInfo from './SampleInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import cloneDeep from 'lodash/cloneDeep'
import cytobands from './data/cytobands.json'


class SearchTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filtered: [], // current filters, exported to globalFilters
            hoverRow: null,
            hoverCol: null,
            scrollToRow: null,
            segmentColor: false,
            columnFiltersDiv: false,
            sampleNotesToggle: false,
            sampleInfoToggle: false,
            table: null,
            export: null,
            cytobands: cytobands,
            ploidy: false,
            showModal: false,
            log2Ploidy: 0,
            log2PloidyTmp: 0
        }
    }

    // editable columns, https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v6/examples/editable-content?file=/src/index.js:391-874
    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.data ? this.data : []];
                    if (Object.keys(data[cellInfo.index]).includes('comments')) {
                        data[cellInfo.index].comments = e.target.innerHTML
                    } else {
                        data[cellInfo.index] = { ...{ comments: e.target.innerHTML }, ...data[cellInfo.index] }
                    }
                    //data.forEach(function(part, index, arr) {arr[index] = "hello world"})

                    this.setState({ data: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.data[cellInfo.index] ? this.data[cellInfo.index][cellInfo.column.id] : null
                }}
            />
        );
    }

    onFilteredChangeCustom = (value, accessor) => {
        var preset = this.props.preset ? this.props.preset : 'No preset'
        this.props.filterAction({ [preset]: { [this.props.table]: { filters: [{ id: accessor, value: value }] } } })
    }

    tableSave = () => {
        // REWRITE FULL TABLE WITH COMMENT VALUES
        axios.post('backend/saveTable', {
            user: this.props.user,
            run: this.props.run,
            sample: this.props.sample,
            data: this.data ? this.data : [],
            analysisType: this.props.table
        }).then(
            res => {
                this.props.addAlert({ message: res.data })
                this.props.backendAction(Math.random(1))
            })
    }

    CNVclick = (e) => {
        this.props.tableClickAction(e)
        this.props.CNVGlobalAction(e)
    }

    segmentScroll = () => {
        if (this.props.segmentClick !== null) {
            var index = Object(this.state.table.getResolvedState().sortedData).map(x => x._original).findIndex(x =>
                x.chromosome === this.props.segmentClick.chromosome &
                x.start.replace(/,/g, '') === this.props.segmentClick.start &
                x.end.replace(/,/g, '') === this.props.segmentClick.end
            )

            // index in unfiltered table for highlight purposes
            var orig_index = this.state.table.props.data.findIndex(x =>
                x.chromosome === this.props.segmentClick.chromosome &
                x.start.replace(/,/g, '') === this.props.segmentClick.start &
                x.end.replace(/,/g, '') === this.props.segmentClick.end
            )

            if (index === -1) return null

            if (this.state.table.state.page !== Math.floor(index / this.state.table.state.pageSize)) {
                //Super-duper hacky, I know you cannot mutate the state, but everything else fails...
                var tmp_table = Object.assign({}, this.state.table) //probably does not deep clone the state
                tmp_table.state.page = Math.floor(index / this.state.table.state.pageSize)
                this.setState({ table: tmp_table })
            }

            try {
                setTimeout(() => {
                    $('.ReactTable_' + this.props.table)[0].scrollIntoView({ behavior: "smooth" }) //scroll to the table
                }, 1)
                setTimeout(() => {
                    $('.ReactTable_' + this.props.table + ' .rt-table .rt-tbody')[0].children[index - (Math.floor(index / this.state.table.state.pageSize) * this.state.table.state.pageSize)].scrollIntoView({ behavior: "smooth", block: "center" })
                }, 10)
            } catch (e) {
                console.log(e)
            }

            this.setState({ segmentColor: orig_index })
            this.props.segmentClickAction(null)
            // setTimeout(() => { this.setState({ segmentColor: null }) }, 3500)
        }
    }

    handleIGV = (x) => {
        this.props.tabIndexAction({ index: 5 })
        if (this.props.table === "SNV2") {
            this.props.locusAction({ locus: 'chr' + x.Chromosome + ":" + x.Start_Position, source: "SNVCNV" })
        } else if (this.props.table === "CNV") {
            this.props.locusAction({ locus: 'chr' + x.chromosome + ":" + x.start + '-' + x.end, source: "SNVCNV" })
        } else if (this.props.table === "Rear") {
            this.props.locusAction({ locus: 'chr' + x.coord, source: "RearTrans" })
        } else if (this.props.table === "Trans") {
            this.props.locusAction({ locus: 'chr' + x.coord, partner: 'chr' + x['coord partner'], source: "RearTrans" })
        }
    }


    checkChange = () => {
        this.props.filterAction({ [this.props.preset]: { [this.props.table]: { columns: Object.values(this.refs).filter(x => x.checked === true).map(x => x.name) } } })
    }
    growColumnFiltersDiv = () => {
        this.setState({ columnFiltersDiv: !this.state.columnFiltersDiv })
    }

    sampleNotesToggle = () => {
        this.setState({ sampleNotesToggle: !this.state.sampleNotesToggle });
    }
    sampleInfoToggle = () => {
        this.setState({ sampleInfoToggle: !this.state.sampleInfoToggle });
    }

    updateTableReference = (r) => {
        if (this.state.table !== r & r !== null) {
            this.setState({ table: r })
        }
    }

    showModal = () => {
        this.setState({ showModal: true })
    }

    hideModal = () => {
        this.setState({ showModal: false })
    }

    changePloidy = () => {
        this.setState({ log2Ploidy: this.state.log2PloidyTmp })
        this.props.changePloidy(this.state.log2PloidyTmp)
        this.setState({ showModal: false })
    }

    resetLog2Ploidy = () => {
        this.setState({ log2Ploidy: 0 })
        this.setState({ log2PloidyTmp: 0 })
        this.props.changePloidy(0)
        this.setState({ showModal: false })
    }

    changeLog2Ploidy = (event) => {
        this.setState({ log2PloidyTmp: event.target.value });
    }

    exportToCSV = (exportCols) => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        var tableData = cloneDeep(this.state.table.getResolvedState().sortedData)
            .map(x => exportCols
                .map(y => [{ [y]: x[y] }][0])
                .reduce(function (acc, val) {
                    return Object.assign(acc, val);
                }, {}))
        tableData.forEach(x => delete x.IGV)
        tableData.forEach(x => delete x.IMGT)
        tableData.forEach(x => exportCols.forEach(y => x[y] = isNaN(Number(x[y])) | isNaN(parseFloat(x[y])) ? x[y] : Number(x[y])))
        const ws = XLSX.utils.json_to_sheet(tableData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, [this.props.sample, this.props.table].join('_') + fileExtension);
        this.tableSave()
    };


    render() {
        var pre_cols = this.props.data ? Object.keys(this.props.data[0]) : null
        if (this.props.table !== 'Summary') {
            pre_cols = pre_cols ?
                pre_cols.includes('comments') ?
                    pre_cols :
                    ['comments', ...pre_cols] :
                'No data'
        }

        // sorting Rear/Trans columns
        if (this.props.table === "Rear" && pre_cols !== "No data") {
            pre_cols = ["comments", "rearrangement class", "locus", "V gene", "D gene", "J gene", "%locus", "%class", "fragments", "locus sum", "segmentation", "junction", "junction nt seq", "sequence context"]

        } else if (this.props.table === "Trans" && pre_cols !== "No data") {
            // pre_cols = ["comments", "class1", "gene", "gene partner", "coord", "coord partner", "frequency", "%class", "fragments", "depth(s)", "gene / gene partner", "sequence context"]
            pre_cols = ["comments", "gene", "gene partner", "coord", "coord partner", "frequency", "%class", "fragments", "depth(s)", "gene / gene partner", "sequence context"]
        }

        // filtering table columns
        var allColumns = this.props.data ? [...pre_cols] : null
        allColumns = allColumns ? allColumns.includes('comments') ? allColumns : ['comments', ...allColumns] : null
        if (this.props.table === 'CNV') {
            allColumns.splice(4, 0, 'length')
            allColumns.splice(5, 0, 'cyt_start')
            allColumns.splice(6, 0, 'cyt_end')
        }

        if (this.props.table === "Rear") {
            allColumns.splice(0, 0, 'IMGT')
        }
        if (['CNV', 'SNV2', "Rear", "Trans"].includes(this.props.table)) {
            allColumns.splice(0, 0, 'IGV')
        }

        // if (this.props.table === 'Rear') {
        //     allColumns = allColumns.filter(x => x !== 'coord')
        // }
        // if (this.props.table === 'Trans') {
        //     allColumns = allColumns.filter(x => !['coord', 'coord partner'].includes(x))
        // }

        //column filters from the global variable
        var checked = this.props.globalFilters[this.props.preset] ?
            this.props.globalFilters[this.props.preset][this.props.table] ?
                this.props.globalFilters[this.props.preset][this.props.table].columns ?
                    this.props.globalFilters[this.props.preset][this.props.table].columns :
                    allColumns :
                allColumns :
            allColumns

        var data = this.props.data ? JSON.parse(JSON.stringify(this.props.data)) : [{ a: 'No data' }]
        this.data = this.props.data
        if (this.props.table !== 'Summary') {
            data.forEach(function (part, index, arr) {
                if (!Object.keys(arr[index]).includes('comments')) {
                    arr[index] = { ...{ comments: '' }, ...arr[index] }
                }
            })

            this.data.forEach(function (part, index, arr) {
                if (!Object.keys(arr[index]).includes('comments')) {
                    arr[index] = { ...{ comments: '' }, ...arr[index] }
                }
            })
        }

        //column filters from the global variable (Redundant? may be replaced with 'checked' maybe?)
        var filterColumns = this.props.globalFilters[this.props.preset] ?
            this.props.globalFilters[this.props.preset][this.props.table] ?
                this.props.globalFilters[this.props.preset][this.props.table].columns :
                null :
            null

        function getTextWidth(text, font) {
            // re-use canvas object for better performance
            const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
            const context = canvas.getContext("2d");
            context.font = font;
            const metrics = context.measureText(text);
            return metrics.width;
        }

        function width(data, col) {
            try { //not really needed, but sometimes old results have 'comment' instead of 'commentS' and stuff breaks
                var width = Math.max(100, ...data.map(y => getTextWidth(y[col].toString()) * 1.7 + 5), getTextWidth(col.toString()) * 1.7 + 25)
                return width
            } catch (e) {
                return (100)
            }
        }

        var columns = this.props.data ?
            pre_cols.map(x => [{
                Header: x == 'probes' ? 'bins' : x,
                accessor: (d) => ['log2'].includes(x) ? Number(d[x]) : d[x],
                id: x,
                minWidth: width(this.props.data, x),
                maxWidth: x !== 'sequence context' ? 300 : width(this.props.data, x),
                Cell: (x === "comments" ?
                    this.renderEditable :
                    null),
                sortMethod: (a, b) => {
                    if (['start', 'end', 'depth', 'probes', 'var num', 'weight', 'Start_Position', 'tumor_DP', 'tumor_AD_ref', 'tumor_AD_alt'].includes(x)) { //length col filtering below in 'CNV'
                        return Number(a.replace(/,/g, '')) > Number(b.replace(/,/g, '')) ? 1 : -1
                    } else if (['chromosome', 'Chromosome'].includes(x)) {
                        return 0
                    }
                    a = a === null || a === undefined ? -Infinity : a
                    b = b === null || b === undefined ? -Infinity : b
                    a = typeof a === "string" ? a.toLowerCase() : a
                    b = typeof b === "string" ? b.toLowerCase() : b
                    if (a > b) { return 1 }
                    if (a < b) { return -1 }
                    return 0;
                }

            }][0]) :
            [{ Header: "No data", accessor: 'a' }]

        var fullColList = columns

        if (this.props.table === 'CNV') { // adding length column here, before column filters, so that it can be seen and filtered in the column filter list
            columns.splice(4, 0, { Header: 'length', accessor: (d) => d['length'], id: 'length', Cell: null, sortMethod: (a, b) => { return Number(a.replace(/,/g, '')) > Number(b.replace(/,/g, '')) ? 1 : -1 } })
            columns.splice(4, 0, { Header: 'cyt_start', accessor: (d) => d['cyt_start'], id: 'cyt_start', Cell: null })
            columns.splice(5, 0, { Header: 'cyt_end', accessor: (d) => d['cyt_end'], id: 'cyt_end', Cell: null })
            columns.filter(x => x.id == 'gene')[0].Header = 'LPD genes'
        }

        if (this.props.table === 'SNV2') {
            columns.splice(0, 0, { Header: 'IGV', accessor: (d) => d['IGV'], id: 'IGV', Cell: null, filterable: false })
            columns = columns.filter(x => x.Header != 'MANE_PLUS_CLINICAL')
            data.forEach((x) => {
                x.IGV = <img src={IGV} alt="IGV icon" className="IGVIcon" style={{ width: '20px', height: '20px', margin: '5px', cursor: 'pointer', marginLeft: '30px' }} onClick={() => this.handleIGV(x)} />
                x.MANE_PLUS_CLINICAL = null
                x.pubmed = x.pubmed ? <div style={{ display: 'flex' }}>{x.pubmed.split('&').map((y, i, row) => {
                    if (i + 1 === row.length) { //last row
                        return <div><a href={"https://pubmed.ncbi.nlm.nih.gov/" + y} target="_blank" rel="noopener noreferrer" class='pubmed'>{y}</a></div>
                    } else {
                        return <div><a href={"https://pubmed.ncbi.nlm.nih.gov/" + y} target="_blank" rel="noopener noreferrer" class='pubmed'>{y}</a>{'&'}</div>
                    }
                })}</div> : null
            })
        }

        if (this.props.table === 'CNV') {
            columns.splice(0, 0, { Header: 'IGV', accessor: (d) => d['IGV'], id: 'IGV', Cell: null, filterable: false })
            data.forEach((x) => {
                x.IGV = <img src={IGV} alt="IGV icon" className="IGVIcon" style={{ width: '20px', height: '20px', margin: '5px', cursor: 'pointer', marginLeft: '30px' }} onClick={() => this.handleIGV(x)} />
                x.length = (x.end.replace(/,/g, '') - x.start.replace(/,/g, '')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                x.start = x.start.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                x.end = x.end.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                x.cyt_start = this.state.cytobands.filter(y => y.CHR === x.chromosome & Number(y.from) <= Number(x.start.replace(/,/g, '')) & Number(y.to) > Number(x.start.replace(/,/g, '')))[0].arm
                x.cyt_end = this.state.cytobands.filter(y => y.CHR === x.chromosome & Number(y.from) <= Number(x.end.replace(/,/g, '')) & Number(y.to) > Number(x.end.replace(/,/g, '')))[0].arm
            })
        }

        if (this.props.table === 'Rear') {
            columns.splice(0, 0, { Header: 'IMGT', accessor: (d) => d['IMGT'], id: 'IMGT', Cell: null, filterable: false })
            data.forEach((x) => {
                x.IMGT = <a href={"https://www.imgt.org/IMGT_vquest/analysis?species=human&receptorOrLocusType=" + x.locus.substr(0, 2) + "&inputType=inline&sequences=%3E1%0A" + x['sequence context'] + "&outputType=html&nbNtPerLine=10000&sv_V_GENEordertable=0&resultType=detailed&sv_V_GENEalignment=false&sv_V_REGIONalignment=false&sv_V_REGIONtranslation=true&sv_V_REGIONprotdisplay=false&sv_V_REGIONprotdisplay2=false&sv_V_REGIONprotdisplay3=false&sv_V_REGIONfrequentAA=false&sv_IMGTjctaResults=true&IMGTrefdirSet=1&IMGTrefdirAlleles=true&V_REGIONsearchIndel=true&nb5V_REGIONignoredNt=0&nb3V_REGIONaddedNt=0"}
                    rel="noopener noreferrer" target="_blank"><img src={IGV} alt="IMGT icon" className="IMGTIcon" style={{ width: '20px', height: '20px', margin: '5px', cursor: 'pointer', marginLeft: '30px' }} /></a>
                if (x["D gene"] === "?") {
                    x["D gene"] = <a href={"https://www.imgt.org/IMGT_vquest/analysis?species=human&receptorOrLocusType=" + x.locus.substr(0, 2) + "&inputType=inline&sequences=%3E1%0A" + x['sequence context'] + "&outputType=html&nbNtPerLine=10000&sv_V_GENEordertable=0&resultType=detailed&sv_V_GENEalignment=false&sv_V_REGIONalignment=false&sv_V_REGIONtranslation=true&sv_V_REGIONprotdisplay=false&sv_V_REGIONprotdisplay2=false&sv_V_REGIONprotdisplay3=false&sv_V_REGIONfrequentAA=false&sv_IMGTjctaResults=true&IMGTrefdirSet=1&IMGTrefdirAlleles=true&V_REGIONsearchIndel=true&nb5V_REGIONignoredNt=0&nb3V_REGIONaddedNt=0"}
                        rel="noopener noreferrer" target="_blank">check IMGT</a>
                }
            })
        }

        if (["Rear", "Trans"].includes(this.props.table)) {
            columns.splice(0, 0, { Header: 'IGV', accessor: (d) => d['IGV'], id: 'IGV', Cell: null, filterable: false })
            data.forEach((x) => {
                if (x.coord !== '') {
                    x.IGV = <img src={IGV} alt="IGV icon" className="IGVIcon" style={{ width: '20px', height: '20px', margin: '5px', cursor: 'pointer', marginLeft: '30px' }} onClick={() => this.handleIGV(x)} />
                } else {
                    x.IGV = <img data-tip={'Missing coordinates for this entry'} src={IGV} alt="IGV icon" className="IGVIcon" style={{ opacity: '0.4', width: '20px', height: '20px', margin: '5px', marginLeft: '30px' }} />
                }
            })
        }

        if (filterColumns !== undefined & filterColumns !== null) {
            columns = columns.filter((x) => filterColumns.includes(x.id))
        }

        var colsWithBlanks = ["af", "eur_af", "gnomad_af", "gnomad_nfe_af", "gnomadg_af", "gnomadg_nfe_af", "max_af"];
        var numFilter = function (filter, row) {
            if (row[filter.id] && row[filter.id] !== "") {
                if (filter.value.includes(";") && (filter.value.split(";")[1] !== "" && filter.value.split(";")[1] !== ">" && filter.value.split(";")[1] !== "<")) {
                    var filter1 = filter.value.split(";")[0]
                    var filter2 = filter.value.split(";")[1]
                    var filterLow = filter1.includes("<") ? Number(filter1.split("<")[1]) : Number(filter2.split("<")[1])
                    var filterHigh = filter2.includes(">") ? Number(filter2.split(">")[1]) : Number(filter1.split(">")[1])

                    if (row[filter.id] < filterLow && row[filter.id] > filterHigh) {
                        return (true)
                    } else {
                        return (false)
                    }
                } else if (filter.value.includes("<")) {
                    if (row[filter.id] < Number(filter.value.split("<")[1])) {
                        return (true)
                    } else {
                        return (false)
                    }
                } else if (filter.value.includes(">")) {
                    if (row[filter.id] > Number(filter.value.split(">")[1])) {
                        return (true)
                    } else {
                        return (false)
                    }
                } else if (filter.value.includes("=")) {
                    if (Number(row[filter.id]) === Number(filter.value.split("=")[1])) {
                        return (true)
                    } else {
                        return (false)
                    }
                } else {
                    return (true)
                }
            } else {
                return (true)
            }
        }
        var charFilter = function (filter, row) {
            if (row[filter.id]) {
                if (filter.value === " ") {
                    return (row[filter.id] === null)
                } else {
                    return (row[filter.id].toLowerCase().match("^" + filter.value.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1").toLowerCase()) !== null)
                    // return (row[filter.id].toLowerCase().includes(filter.value.toLowerCase()))
                }
            } else {
                return (null)
            }
            // Honestly I have no idea why I have to check if row[filter.id] exsists in the line above. Without the check, there's a crash saying that it's empty if you filter column 5 in first SNV table...
            // The column is almost the same as the others and I cannot even put a breakpoint before when debugging. Anyway, works now.
        }
        var arrFilter = function (filter, row) {
            if (filter.value === null || filter.value.length === 0) {
                return (true)
            } else if (filter.id == 'Variant_Classification' & filter.value.length > 0) {
                var flts = filter.value.map(x => x.toString().toLowerCase())
                var val = row[filter.id].toString().toLowerCase()
                var result = flts.map(x => val.includes(x)).some(value => value === true)
                return (result)
            } else if (filter.value.length > 0) {
                return (filter.value.map(x => x.toString().toLowerCase()).includes(row[filter.id].toString().toLowerCase()))
            } else {
                return (null)
            }
        }
            
        var varFilter = function (filter, row) {
            if (row[filter.id] && filter.value !== null && filter.value !== "") {
                if (filter.value === null || filter.value.constructor === Array) {
                    return (arrFilter(filter, row))
                } else if (typeof row[filter.id] === "number" || !isNaN(Number(row[filter.id]))) {
                    return (numFilter(filter, row))
                } else {
                    return (charFilter(filter, row))
                }
            } else {
                if (filter.value === '' || filter.value === ' ' || filter.value === null || filter.value[0] === '') {
                    return (true)
                } else if (colsWithBlanks.includes(filter.id) && row[filter.id] === "") {
                    return (true)
                } else {
                    return (null)
                }
            }
        }

        //set column specific filters based on data type
        for (var i = 0; i < columns.length; i++) {
            columns[i].filterMethod = (filter, row) => varFilter(filter, row)
        }

        // natural sorting helper
        const comparator = (a, b) => {
            return a.toString().localeCompare(b.toString(), 'en', { numeric: true })
        };
        // add filtering by options
        var categColums = ["Chromosome", "Variant_Classification",
            "rearrangement class"];
        const consequences = ['transcript_ablation', 'splice_acceptor_variant', 'splice_donor_variant', 'stop_gained',
         'frameshift_variant', 'stop_lost', 'start_lost', 'transcript_amplification', 'inframe_insertion', 'inframe_deletion',
          'missense_variant', 'protein_altering_variant', 'splice_region_variant', 'splice_donor_5th_base_variant',
           'splice_donor_region_variant', 'splice_polypyrimidine_tract_variant', 'incomplete_terminal_codon_variant',
            'start_retained_variant', 'stop_retained_variant', 'synonymous_variant', 'coding_sequence_variant', 'mature_miRNA_variant',
             '5_prime_UTR_variant', '3_prime_UTR_variant', 'non_coding_transcript_exon_variant', 'intron_variant',
              'NMD_transcript_variant', 'non_coding_transcript_variant', 'upstream_gene_variant', 'downstream_gene_variant',
               'TFBS_ablation', 'TFBS_amplification', 'TF_binding_site_variant', 'regulatory_region_ablation', 'regulatory_region_amplification',
                'feature_elongation', 'regulatory_region_variant', 'feature_truncation', 'intergenic_variant']
        columns.filter(x => categColums.includes(x.id)).forEach(x => {
            x.Filter = ({ filter, onChange }) =>
                <Select
                    onChange={event => onChange(event !== null ? event.map(x => x.value) : event)}
                    //[filter].map(x => x.value.length !== 0 ? x : null).filter(x => x !== null).map(x => [{value: x.value[0], label: x.value[0]}][0])
                    value={filter ? filter.value.map(x => [{ value: x, label: x }][0]) : null}
                    closeMenuOnSelect={false}
                    defaultValue={null}
                    isMulti
                    options={x.Header == 'Variant_Classification' ? consequences.map(y => [{ value: y, label: y }][0]) : [...new Set(this.props.data.map(y => y[x.id]))].sort(comparator).map(y => [{ value: y, label: y }][0])}
                    className="basic-multi-select filterByOptions"
                    classNamePrefix="select"
                    style = {{maxHeight: '30px'}}
                />
        })

        //Row filters from global variable
        var filters = this.props.globalFilters[this.props.preset] ?
            this.props.globalFilters[this.props.preset][this.props.table] ?
                this.props.globalFilters[this.props.preset][this.props.table].filters : null : null

        var saveTableBtn = this.props.table !== 'Summary' ? <Button variant="secondary" size="sm" className="fillAvailableBtns" onClick={this.tableSave}>Save table comments</Button> : null;

        var sampleNotesInfoBtn = null;
        if (!["Rear", "Trans", "Summary"].includes(this.props.table)) {
            sampleNotesInfoBtn = <Button variant="info" size="sm" className="fillAvailableBtns" onClick={this.sampleNotesToggle}>{this.state.sampleNotesToggle ? <div><FontAwesomeIcon icon={faAngleUp} /> Hide sample notes</div> : <div><FontAwesomeIcon icon={faAngleDown} /> Show sample notes</div>}</Button>;
        } else if (["Rear", "Trans"].includes(this.props.table)) {
            sampleNotesInfoBtn = <Button variant="info" size="sm" className="fillAvailableBtns" onClick={this.sampleInfoToggle}>{this.state.sampleInfoToggle ? <div><FontAwesomeIcon icon={faAngleUp} /> Hide sample info</div> : <div><FontAwesomeIcon icon={faAngleDown} /> Show sample info</div>}</Button>;
        }


        var columnFiltersDiv_grow = this.state.columnFiltersDiv ? { label: <div><FontAwesomeIcon icon={faAngleUp} /> Hide column filtering</div>, height: $('#columnFiltersDiv_height')[0].clientHeight } : { label: <div><FontAwesomeIcon icon={faAngleDown} /> Show column filtering</div>, height: "0px" };
        var columnFiltersDiv = null;
        var columnFiltersBtn = null;
        var allColumnsDisp = fullColList.filter(y => allColumns?.includes(y.id))
        if (this.props.table !== 'Summary') {
            columnFiltersDiv =
                <div id="columnFiltersDiv_grow" style={{ height: columnFiltersDiv_grow.height }}>
                    <div id="columnFiltersDiv_height" className="columnFiltersDiv_content">
                        <ol>
                            {allColumnsDisp.map((x) => {
                                return (
                                    <li key={x.id}>
                                        <input type="checkbox" name={x.id} ref={x.id} onChange={this.checkChange} checked={checked.includes(x.id)} />
                                        <label htmlFor={x.id} style={{ 'paddingLeft': '5px' }}>{x.Header}</label>
                                        <br></br>
                                    </li>
                                )
                            }, this)}
                        </ol>
                    </div>
                </div>;
            columnFiltersBtn = <Button variant="info" size="sm" className="fillAvailableBtns" onClick={this.growColumnFiltersDiv} > {columnFiltersDiv_grow.label} </Button>;
        }

        /*if(this.state.scrollToRow !== null) {
            this.reactTable.scroll(0, this.state.scrollToRow)
            this.setState({scrollToRow: null})
        }*/

        if (this.props.segmentClick !== null) {
            this.segmentScroll()
        }

        // var exportCols = columns.map(x => x.id).filter(x => x !== 'IGV')
        var exportCols = columns.map(x => x.id).filter(x => !["IMGT", "IGV"].includes(x))

        // var filtered_data = this.state.table ? Object(this.state.table.getResolvedState().sortedData)
        //     .map(x => exportCols
        //         .map(y => [{ [y]: x[y] }][0])
        //         .reduce(function (acc, val) {
        //             return Object.assign(acc, val);
        //         }, {}))
        //     : []

        if (filters == null) { filters = [] }

        var tableHeight = 500;
        if ($(window).height() && ($(window).height() - 290 > 500)) {
            tableHeight = $(window).height() - 290;
        }

        if (this.props.table == 'CNV') {
            var ploidy
            ploidy = <button style={{ background: this.state.log2Ploidy ? '#10E5AA' : '#DDDDDD' }} className="fillAvailableBtns btn btn-secondary btn-sm" onClick={(e) => this.showModal()}>Ploidy {this.state.log2Ploidy ? this.state.log2Ploidy : '-'}</button>

            if (this.state.log2Ploidy) {
                data.forEach(x => x.log2 = Number(x.log2) + Number(this.state.log2Ploidy))
            }
        }

        return (
            <div>
                <SampleNotes
                    tab={this.props.table}
                    show={this.state.sampleNotesToggle}
                />
                <SampleInfo
                    info={this.props.info}
                    show={this.state.sampleInfoToggle}
                />
                <Row >
                    <Col xs={12}>
                        {columnFiltersDiv}
                    </Col>
                </Row >
                <Row className="tabButtonsRow">
                    <Col xs={2}>
                        {sampleNotesInfoBtn}
                    </Col>
                    <Col xs={2}>
                        {columnFiltersBtn}
                    </Col>
                    <Col xs={4}>
                    </Col>
                    <Col xs={4}>
                        <Row >
                            <Col xs={3}>
                                {ploidy}
                            </Col>
                            <Col xs={5}>
                                {saveTableBtn}
                            </Col>
                            <Col xs={4}>
                                <button className="exportReactCSVlink fillAvailableBtns btn btn-secondary btn-sm" onClick={(e) => this.exportToCSV(exportCols)}>Export</button>
                            </Col>
                        </Row >
                    </Col>
                </Row >
                <ReactTable
                    ref={r => { this.updateTableReference(r) }}
                    data={data}
                    columns={columns}
                    className={"-striped -highlight ReactTable_" + this.props.table}
                    style={{ height: tableHeight }}
                    // showPagination={true}
                    filterable
                    onFilteredChange={(filtered, column, value) => {
                        this.onFilteredChangeCustom(value, column.id || column.accessor);
                    }}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}

                    defaultFiltered={filters ? filters : []}
                    defaultPageSize={50}
                    getTdProps={(state, rowInfo, column, instance) => {
                        if (typeof rowInfo !== "undefined" & this.props.table === 'CNV') {
                            if (this.props.segmentClick !== null) {
                                //this.segmentScroll()
                            }

                            if (rowInfo.row.loh === '' | rowInfo.row.loh === undefined) {
                                if (this.state.segmentColor !== false) {
                                    console.log()
                                }
                                return {
                                    style: {
                                        fontWeight: 400,
                                        background: this.state.segmentColor === rowInfo.index ? 'yellow' : this.state.hoverRow === rowInfo.index & !['comments', 'IGV', 'IMGT'].includes(this.state.hoverCol) ? '#92C9FA' : '#E1E5E8',
                                        // background: this.state.segmentColor === rowInfo.index ? 'yellow' : '#E1E5E8',
                                        cursor: ['comments', 'IGV', 'IMGT'].includes(this.state.hoverCol) ? 'auto' : 'pointer'
                                    },
                                    onClick: () => {
                                        if (!['comments', 'IGV', 'IMGT'].includes(this.state.hoverCol)) {
                                            this.CNVclick(rowInfo)
                                            this.setState({ segmentColor: rowInfo.index })
                                            $('.vaf')[0].scrollIntoView({ behavior: "smooth", block: "start" })
                                        }
                                    },
                                    onMouseEnter: () => {
                                        this.setState({ hoverRow: rowInfo.index, hoverCol: column.Header })
                                    },
                                    onMouseLeave: () => {
                                        this.setState({ hoverRow: null, hoverCol: null })
                                    }
                                }
                            } else {
                                return {
                                    style: {
                                        fontWeight: 200,
                                        background: this.state.segmentColor === rowInfo.index ? 'yellow' : this.state.hoverRow === rowInfo.index & !['comments', 'IGV', 'IMGT'].includes(this.state.hoverCol) ? '#92C9FA' : 'white',
                                        // background: this.state.segmentColor === rowInfo.index ? 'yellow' : 'white',
                                        cursor: this.state.hoverCol === 'comments' ? 'auto' : 'pointer'
                                    },
                                    onClick: () => {
                                        if (this.state.hoverCol !== 'comments') {
                                            this.CNVclick(rowInfo)
                                            this.setState({ segmentColor: rowInfo.index })
                                            $('.vaf')[0].scrollIntoView({ behavior: "smooth", block: "start" })
                                        }
                                    },
                                    onMouseEnter: () => {
                                        this.setState({ hoverRow: rowInfo.index, hoverCol: column.Header })
                                    },
                                    onMouseLeave: () => {
                                        this.setState({ hoverRow: null, hoverCol: null })
                                    }
                                }
                            }
                        } else if (typeof rowInfo !== "undefined" & (this.props.table === 'SNV2' || this.props.table === 'Rear' || this.props.table === 'Trans')) {
                            return {
                                style: {
                                    background: this.state.segmentColor === rowInfo.index ? 'yellow' : null,
                                    cursor: column.Header !== 'sequence context' ? ['comments', 'IGV', 'IMGT'].includes(column.Header) ? 'auto' : 'pointer' : 'copy'
                                },
                                onClick: () => {
                                    if (!['comments', 'IGV', 'IMGT', 'sequence context'].includes(column.Header)) {
                                        this.setState({ segmentColor: this.state.segmentColor === rowInfo.index ? null : rowInfo.index })
                                    }
                                    if ('sequence context' == column.Header) {
                                        navigator.clipboard.writeText(rowInfo.row[column.Header])
                                        this.props.addAlert({ message: 'sequence copied' })
                                    }
                                },
                                'data-tip': column.Header === 'sequence context' ? 'Click on the sequence to copy it to the clipboard' : null
                            }
                        }

                        return {} // little children die if this return is gone
                    }}
                />
                <ReactTooltip />
                <br />
                <Modal show={this.state.showModal} onHide={this.hideModal} size="lg" centered>
                    <Modal.Header closeButton >
                        <Modal.Title>Change sample ploidy</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                Sample ploidy
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Log2 ratio <input style={{ maxWidth: '40%' }} type="number" name="Log2 ratio" value={this.state.log2PloidyTmp} onChange={this.changeLog2Ploidy} />
                            </Col>
                            <Col>
                                Reference copy number: {Number(((2 ** Number(this.state.log2PloidyTmp)) * 2).toFixed(3))}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlignLast: 'center' }}>
                                <Button onClick={this.resetLog2Ploidy} >Reset ploidy</Button>
                            </Col>
                            <Col style={{ textAlignLast: 'center' }}>
                                <Button onClick={this.changePloidy} >Confirm</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

//Passing global state as props
const mapStateToProps = (state) => {
    return {
        globalFilters: state.globalFilters,
        preset: state.preset ? state.preset : null,
        user: state.user ? state.user : '',
        sample: state.sample,
        columns: state.columns,
        run: state.run,
        runs: state.runs,
        tableClick: state.tableClick,
        segmentClick: state.segmentClick
    }
}
const mapDispatchToProps = { filterAction, presetAction, addAlert, tableClickAction, segmentClickAction, tabIndexAction, locusAction, CNVGlobalAction, backendAction } // WARNING THIS SHIT CANNOT BE FUNCTION IT MUST BE AN OBJECT OR YOU WILL WASTE YOUR DAY ON THIS TUTORIALS LIE 
export default connect(mapStateToProps, mapDispatchToProps)(SearchTable)
